import styled from 'styled-components';
import { colors, breakPoints } from '../../static/theme';

const ValidationWrapper = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 40px auto;
  padding: 0 40px;
  box-sizing: border-box;

  .validation__header {
    display: inline-flex;
    justify-content: space-between;
    width: 100%;

    @media screen and (max-width: ${breakPoints.tablet.minWidth}px) {
      flex-direction: column;
      margin-left: 20px;
      width: 90%;

      .validation__info {
        margin-bottom: 20px;
      }
    }
  }

  .btn_edit {
    outline: 0;
    cursor: pointer;
    height: 38px;
    width: 38px;
    background: white;
    border-radius: 100%;
    position: relative;
    border: 0;
    img {
      width: 17px;
    }
  }

  .date {
    .editable_block {
      .editable_input {
        margin-right: 2px !important;
        margin-left: 2px  !important;
      }
    }
  }

  .validation__container {
    & > table {
      width: 100%;
      margin: 40px 0;
    }

    .recap_block {
      float: right;

      .recap_title {
        width: 296px;
        background-color: #1e3c81;
        color: white;
        padding: 12px;
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        margin-bottom: 10px;
      }

      .recap_content {
        width: 379px;
        .editable_block {
          display: block;
          position: relative;
          > div {
            background: ${colors.grey}
            width: 296px;
            padding: 12px;
            padding-bottom: 0;
          }
          .wrapper {
            display: flex;
            justify-content: space-between;
          }
          .edition_block_button {
            width: 56px;
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            background: transparent;
          }
        }
        .editable_block-price {
          border: 1px solid blue;
          background: white !important;
        }
      }
    }
  }

  .info_vehicule {
    h2 {
      line-height: 22px;
    }
    span {
      font-weight: 500;
    }
  }
  textarea {
    resize: none;
    border: 0;
    background: none;
    line-height: 22px;
    font-family: 'Montserrat';
    padding: 0 10px;
    width: 100%;
    height: 22px;
  }
  textarea:focus {
    outline: none;
  }
  .table_designation tr:first-child th:nth-child(3) {
    width: 25%;
  }
  .validation__footer {
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
    margin: 40px 0;
    line-height: 22px;
    color: ${colors.RaisinBlack};
    p {
      padding-bottom: 10px;
    }
    @media screen and (max-width: ${breakPoints.tablet.minWidth}px) {
      flex-direction: column;
      margin-left: 20px;
      width: 90%;

      & > div {
        margin-bottom: 20px;
      }
    }
  }
  .recap_table {
    width: 288px !important;
    tr {
      line-height: 22px;
    }
    @media screen and (max-width: ${breakPoints.tablet.minWidth}px) {
      justify-content: center;
    }
    & > table {
      border-collapse: separate;
      border-spacing: 0px 10px;
      text-align: left;
      &:first-child {
        flex: 0 0 15%;
        text-align: center;
      }
      &:nth-child(2),
      &:nth-child(3) {
        flex: 0 0 25%;
      }
    }
  }
  .adress {
    margin-bottom: 20px;
    h1 {
      font-weight: bold;
      font-size: 35px;
      line-height: 43px;
      margin-bottom: 40px;
      &.detail__title {
        color: ${colors.blue};
        margin-bottom: 20px;
        font-size: 22px;
      }
    }
    h2 {
      font-weight: bold;
      line-height: 22px;
    }
    p {
      font-weight: 500;
      line-height: 22px;
    }
  }

  .submit {
    cursor: pointer;
    border: 0;
    padding: 10px 21px;
    border-radius: 33px;
    background-color: ${colors.green};
    color: ${colors.white};
    display: flex;
    justify-content: center;
    align-items: center;
    outline: 0;
    text-transform: uppercase;
    font-family: 'Montserrat';
    min-width: 411px;
    margin: 0 auto;
    img {
      padding-right: 10px;
    }
  }
  .table_designation {
    border-collapse: separate;
    border-spacing: 5px 10px;
    table-layout: fixed;
    tr {
      background: ${colors.grey};
      color: ${colors.RaisinBlack};
      line-height: 22px;
      td {
        padding: 11px 12px;
      }
    }
  }
`;

export default ValidationWrapper;
