import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import config from 'config';
import Loader from 'components/Loader';
import { ModalContext } from 'context/ModalContext';
import viewConstant from 'utils/view.constant';
import withClientValidationWrapper from './ClientValidationWrapper';
import { DownloadPdf, DownloadMedias } from '../../utils/saveFile';
import ConfirmationMessage from './ConfirmationMessage';

function ClientValidation({ className }) {
  const [file, setFile] = useState(null);
  const [garage, setGarage] = useState(null);
  const [media, setMedia] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [checked, setChecked] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isTreated, setIsTreated] = useState(false);

  const { refId } = useParams();
  const history = useHistory();

  const { setOpen, setData, view, setView } = useContext(ModalContext);
  const { QUOTE_ACCEPTED } = viewConstant;

  const fetchFile = async () => {
    const result = await fetch(`${config.managerUrl}/files/getFileByRefId/${refId}`);
    const data = await result.json();
    if (data.data?.IsDefinitlyClosed) {
      setIsTreated(true);
    }

    setFile(data.data);
    await GarageFile(data.data.garageId);
  };

  const GarageFile = async idGarage => {
    const result = await fetch(`${config.managerUrl}/garages/${idGarage}`);
    const data = await result.json();
    setGarage(data);
  };

  const fetchMedia = async () => {
    const result = await fetch(`${config.managerUrl}/mediaCheck/getMedia/${refId}`);
    const data = await result.json();
    setMedia(data);
  };

  useEffect(() => {
    setLoading(true);
    fetchFile();
    fetchMedia();
    setLoading(false);
  }, []);

  const sendAgreementToGarage = async () => {
    setIsSubmitting(true);
    const {
      licensePlate,
      totalTTC,
      documentInfo: { documentUrl, fileName },
    } = file;
    const { phone } = garage;
    const res = await fetch(`${config.managerUrl}/documents/sendAgreementToGarage`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
      body: JSON.stringify({
        licensePlate,
        documentUrl,
        fileName,
        garagePhone: phone,
        totalTTC,
        refId,
      }),
    });
    const { error } = await res.json();
    setIsSubmitting(false);
    if (!error) {
      setView(QUOTE_ACCEPTED);
    }
  };

  const gotoPage = async page => {
    const result = await fetch(`${config.managerUrl}/brand/email/${file.replayFromEmail}`);
    const data = await result.json();
    history.push(`/${data.data?.path}/${page}`);
  };

  const downloadQoute = () => {
    DownloadPdf(refId);
  };

  if (isTreated) {
    return (
      <h1 style={{ textAlign: 'center', marginTop: '20%', fontSize: '25px' }}>
        Ce devis a déjà été traité
      </h1>
    );
  }

  if (isLoading) {
    return <Loader />;
  }

  return view ? (
    <ConfirmationMessage data={garage} view={view} />
  ) : (
    <div className={className}>
      <div className="section">
        <h1 className="welcome">Bonjour,</h1>
        <div className="message">
          le garage <b>{garage?.corporate} </b>
          vous transmet un devis pour votre véhicule immatriculé <b>{file?.licensePlate}</b> ainsi
          que des photos et/ou vidéos pour vous expliquer la situation.
        </div>
        {file?.garageComment && (
          <div className="comment">
            Voici les commentaires du réparateur : {file?.garageComment}
          </div>
        )}
        <div className="amount">
          Montant du devis <br />
          {file?.totalTTC} €
        </div>
        <div className="download_files">
          <span className="qoute">
            <i className="icon-download" />
            <button type="button" onClick={downloadQoute}>
              Télécharger le devis
            </button>
          </span>
          <span className="media">
            <i className="icon-download" />
            <div>
              <button type="button" onClick={() => DownloadMedias(refId)}>
                Télécharger le(s) contenu(s) média associé(s)
              </button>
              <em> au format .zip, ouverture sur un ordinateur conseillée</em>
            </div>
          </span>
        </div>
        <div className="media-list">
          {media?.data.urls &&
            media?.data?.urls.map((el, i) => (
              <a href={el.url || el} target="_blank" rel="noreferrer" key={el.url}>
                Voir le média {i + 1}
              </a>
            ))}
        </div>
        <div className="termes">
          <input
            type="checkbox"
            id="conditions"
            name="conditions"
            onClick={() => setChecked(!checked)}
            defaultChecked={checked}
          />
          <label htmlFor="conditions">
            <span>j’accepte </span>
            <button type="button" onClick={() => gotoPage('cgv')}>
              les termes et conditions du garage
            </button>
            <span> et également </span>
            <button type="button" onClick={() => gotoPage('rgpd')}>
              la charte de protection des données.
            </button>
          </label>
        </div>
        <div className="buttons">
          <button
            type="button"
            className="refuse"
            onClick={() => {
              setOpen(true);
              setData({
                ...file,
                garagePhone: garage.phone,
                garageName: garage.corporate,
              });
            }}
          >
            REFUSER CE DEVIS
          </button>
          <button
            type="button"
            className="accept"
            disabled={!checked || isSubmitting}
            onClick={sendAgreementToGarage}
          >
            ACCEPTER CE DEVIS
          </button>
        </div>
      </div>
    </div>
  );
}

ClientValidation.propTypes = {
  className: PropTypes.string,
};

export default withClientValidationWrapper(ClientValidation);
