import styled from 'styled-components';

const TestWrapper = styled.div`
  h1 {
    text-align: center;
    border-bottom: 5px solid #1e3c81;
    width: 200px;
    margin: auto;
    border-radius: 50px;
    margin-top: 20px;
  }
  .container {
    margin: 20px;
    display: flex;
    flex-wrap: wrap;

    .card {
      border-radius: 5px;
      display: flex;
      width: 44%;
      border: 1px solid black;
      margin: 2%;
      img {
        width: 50%;
      }
    }

    .selected-card {
      position: fixed;
      top: 0;
      width: 35%;
    }
  }

  .interpretation {
    overflow-y: scroll;
    height: 164px;
    background: #efe8e8;
    padding: 2px;

    ul {
      margin-top: 10px;
      li {
        margin: 10px;
        list-style: inside;
      }
    }
  }

  .loading {
    background: #37445573;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    color: #1e3c81;
  }
`;

export default TestWrapper;
