import React from 'react';
import Wrapper from './RejectedDocumentWrapper';

const RejectedDocument = () => {
  return (
    <Wrapper>
       <div className="rejected-popup">
          <div className="rejected-popup-message">
            Ce document a bien été rejeté. 
            <br /> Le garage a été averti de ce rejet.
          </div>
        </div>
    </Wrapper>
  )
}

export default RejectedDocument;