import styled from 'styled-components';

export default Component => styled(Component)`
  display: flex;
  flex-direction: column;
  align-items: center;
  .section {
    text-align: center;
    width: 520px;
    .welcome {
      margin-top: 65px;
      color: #091f41;
      font-size: 18px;
      font-weight: bold;
    }
    .message {
      margin: 45px auto 0;
      width: 90%;
      color: #161616;
      font-family: Montserrat;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 22px;
    }
    .amount {
      margin: 40px auto 0;
      box-sizing: border-box;
      width: 100%;
      border: 1px solid #e4e4e4;
      color: #091f41;
      font-size: 28px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 45px;
    }
    .comment {
      margin-top: 20px;
    }
    a {
      color: #54a8de;
      font-size: 14px;
      font-weight: 600;
      line-height: 18px;
    }
    .download_files {
      margin-top: 20px;
      span {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        text-align: left;
        i {
          margin-right: 10px;
          width: 15px;
          height: 15px;
          color: #54a8de;
        }
        em {
          font-size: 14px;
        }
      }
      button {
        cursor: pointer;
        font-family: 'Montserrat';
        border: none;
        background: transparent;
        color: #54a8de;
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
        text-decoration: underline;
        text-align: left;
      }
    }
    .media-list {
      text-align: left;
      padding-left: 25px;
      display: flex;
      flex-direction: column;
      a {
        margin-bottom: 5px;
      }
    }
    .termes {
      margin-top: 40px;
      display: flex;
      align-items: flex-start;
      color: #091f41;
      font-size: 14px;
      font-weight: 600;
      width: 100%;
      button {
        border: 0;
        background: transparent;
        color: #54a8de;
        font-family: 'Montserrat';
        padding: 0;
        font-size: 14px;
        text-decoration: underline;
        font-weight: 600;
        line-height: 18px;
        cursor: pointer;
      }
      label {
        line-height: 22px;
        text-align: left;
        margin-left: 4px;
      }
    }
    .buttons {
      width: 100%;
      margin-top: 30px;
      margin-bottom: 50px;
      display: flex;
      justify-content: flex-end;
      .refuse {
        color: #d0021b;
        box-sizing: border-box;
        height: 37px;
        width: 181px;
        border: 1px solid #d0021b;
        border-radius: 2px;
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
        cursor: pointer;
        margin-right: 20px;
      }
      .accept {
        height: 36px;
        width: 190px;
        border-radius: 2px;
        border: none;
        background-color: #50b154;
        color: #ffffff;
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
        cursor: pointer;
        &[disabled] {
          cursor: not-allowed;
          opacity: 0.7;
        }
      }
    }
  }
  @media (max-width: 768px) {
    .section {
      width: 90%;
      .welcome {
        margin-top: 50px;
      }
      .amount {
        font-size: 20px;
      }
    }
  }
`;
