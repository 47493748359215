import { diffWordsWithSpace } from 'diff';

export default (originStr, modifiedStr) => {
  let deleteIndex = 0;

  return diffWordsWithSpace(originStr, modifiedStr)
    .filter(row => row.value !== ' ')
    .map(row => {
      let rowResult = { value: row.value };
      const changeArr = row.value.split(' ').filter(w => !['', ',', ':'].includes(w));
      if (row.added) {
        const changeIndex = modifiedStr.split(' ').findIndex(w => w === changeArr[0]);
        rowResult = {
          type: 'add',
          startIndex: changeIndex,
          value: row.value,
          nbOfWords: changeArr.length,
        };
      }

      if (row.removed) {
        rowResult = {
          type: 'delete',
          nbOfWords: changeArr.length,
          startIndex: deleteIndex,
          value: row.value,
        };
      }

      if (row.removed === undefined) {
        deleteIndex += changeArr.length;
      }

      return rowResult;
    })
    .filter(row => row.type);
};
