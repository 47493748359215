import React from 'react';
import PropTypes from 'prop-types';
import viewConstant from 'utils/view.constant';
import ConfirmationMessageWrapper from './ConfirmationMessageWrapper';

function ConfirmationMessage({ className, data, view }) {
  const { NEW_QUOTE, QUOTE_ACCEPTED, QUOTE_REFUSED } = viewConstant;
  const {
    corporate: garageName,
    address: garageAddress,
    email: garageEmail,
    phone: garagePhone,
    city: garageCity,
    postalCode: garagePostalCode,
  } = data;

  return (
    <div className={className}>
      <div className="section">
        <div className="thanks">Merci</div>
        <div className="message">
          <p>
            Le Garage <b>{garageName} </b>
            {view === NEW_QUOTE && (
              <span>
                sera informé de votre refus ainsi que des raisons de celui-ci. <br />
                Vous serez notifié(e) par e-mail dès que le nouveau devis sera disponible.
              </span>
            )}
            {view === QUOTE_ACCEPTED && (
              <span>
                procédera à la réparation dans les plus brefs délais. <br />
                Vous serez recontacté(e) prochainement pour récupérer votre véhicule.
              </span>
            )}
            {view === QUOTE_REFUSED && (
              <span>
                sera notifié de votre refus. <br />
                Vous serez recontacté(e) prochainement pour récupérer votre véhicule.
              </span>
            )}
          </p>
          <p className="cordially">Bien cordialement,</p>
          <p className="contact-info">
            Le garage {garageName} <br />
            {garageEmail} <br />
            {garagePhone} <br />
            {garageAddress} <br />
            {garagePostalCode} {garageCity} <br />
          </p>
        </div>
      </div>
    </div>
  );
}

ConfirmationMessage.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object,
  view: PropTypes.string,
};

export default ConfirmationMessageWrapper(ConfirmationMessage);
