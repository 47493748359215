import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import TextItem from 'components/TextItem';
import ToolTip from 'components/ToolTip';
import Loader from 'components/Loader';
import Wrapper from './PreviewWrapper';

const Preview = ({ data, setCopiedElement, disabled }) => {
  const src = data.imagesUrls.sort((a, b) => a.page - b.page).map(row => row.url);
  const imageRef = useRef(null);
  const textRef = useRef(null);
  const toolTipRef = useRef(null);

  const [toolTip, setTooltip] = useState(null);
  const [page, setPage] = useState(1);
  const [imgLoading, setImgLoading] = useState(true);
  const [dimensions, setDimensions] = useState({
    height: 0,
    width: 0,
  });

  const onWindowResized = () => {
    setDimensions({
      height: imageRef.current.offsetHeight,
      width: imageRef.current.offsetWidth,
    });
  };

  useEffect(() => {
    window.addEventListener('resize', onWindowResized);

    return () => window.removeEventListener('resize', onWindowResized);
  });

  useEffect(() => {
    if (!imgLoading) {
      setDimensions({
        height: imageRef.current.offsetHeight,
        width: imageRef.current.offsetWidth,
      });
    }
  }, [imgLoading]);

  const handleClick = e => {
    if (
      textRef &&
      textRef.current.className !== e.target.className &&
      toolTipRef &&
      toolTipRef.current &&
      !toolTipRef.current.contains(e.target)
    ) {
      setTooltip(null);
      // setTooltipRef(null);
    }
  };

  return (
    <Wrapper imgHeight={dimensions.height} imgWidth={dimensions.width} onClick={handleClick}>
      {src.length > 1 && (
        <div className="pagination">
          <button
            type="button"
            className="pagination-prev"
            onClick={() => {
              setImgLoading(true);
              setPage(page - 1);
            }}
            disabled={page === 1}
          >
            <i className="icon-arrow-left" />
          </button>
          <div className="page-info">
            {page}/{src.length}
          </div>
          <button
            type="button"
            className="pagination-next"
            onClick={() => {
              setImgLoading(true);
              setPage(page + 1);
            }}
            disabled={page === src.length}
          >
            <i className="icon-arrow-right" />
          </button>
        </div>
      )}
      {imgLoading && <Loader />}
      <img src={src[page - 1]} alt="facture" ref={imageRef} onLoad={() => setImgLoading(false)} />
      {!disabled && (
        <div className="ocr">
          {!imgLoading &&
            data.data.fullText.map(
              (item, index) =>
                item.page === page - 1 && (
                  <TextItem
                    key={index} // eslint-disable-line react/no-array-index-key
                    item={item}
                    index={index}
                    setTooltip={setTooltip}
                    textRef={textRef}
                  />
                ),
            )}
          {toolTip && (
            <ToolTip
              item={toolTip.item}
              handleClose={() => setTooltip(null)}
              toolTipRef={toolTipRef}
              setCopiedElement={setCopiedElement}
            />
          )}
        </div>
      )}
    </Wrapper>
  );
};

Preview.propTypes = {
  data: PropTypes.object,
  setCopiedElement: PropTypes.func,
  disabled: PropTypes.bool,
};

export default Preview;
