import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/Modal';

export const ModalContext = React.createContext();

export const ModalProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState(false);
  const [view, setView] = useState(null);

  return (
    <ModalContext.Provider value={{ setOpen, setData, view, setView }}>
      {open && <Modal setOpen={setOpen} data={data} setView={setView} />}
      {children}
    </ModalContext.Provider>
  );
};

ModalProvider.propTypes = {
  children: PropTypes.object,
};
