import styled from 'styled-components';

export default (Component) => styled(Component)`
  padding: 40px 30px;
  text-align: justify;
  text-justify: inter-word;
  color: #1E3C81;
  .title {
    border: 1px solid #1E3C81;
    padding: 20px;
    text-align: left;
    text-transform: uppercase;
    font-size: 25px;
    line-height: 30px;
    text-transform: uppercase;
  }
  p {
    padding: 20px 0;
    line-height: 25px;
  }
`;