import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Loader from 'components/Loader';
import config from 'config';
import { Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import Form from './ValidationForm';
import initialValues from './initialValues';
import validationSchema from './validationSchema';

const Validation = ({ data, token }) => {
  const history = useHistory();
  const [isLoadingEdit, setLoadingEdit] = useState(false);

  if (data?.garageValidationDate) {
    return (
      <div style={{ textAlign: 'center', marginTop: '15%' }}>
        <h1 style={{ fontSize: 25 }}>
          La validation de {data.isBill ? 'cette facture' : 'ce devis'} a déjà été réalisée.
        </h1>
      </div>
    );
  }

  return !isLoadingEdit ? (
    <Formik
      validationSchema={() => validationSchema(data.isPreValidate, true)}
      onSubmit={async values => {
        setLoadingEdit(true);
        const editResult = await fetch(`${config.managerUrl}/documents/update/${token}`, {
          method: 'PUT',
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
          body: JSON.stringify({
            ...data,
            data: { ...data.data, ...values, date: `${values.day}/${values.month}/${values.year}` },
          }),
        });

        const { error } = await editResult.json();
        setLoadingEdit(false);
        if (!error) {
          history.push('/confirmation', { isBill: data.isBill });
        }
      }}
      initialValues={initialValues(data)}
    >
      {props => <Form {...props} isBill={data.isBill} />}
    </Formik>
  ) : (
    <Loader />
  );
};

Validation.propTypes = {
  token: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
};

export default Validation;
