import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const ZoomWrapper = styled.div`
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1000;
  i {
    position: absolute;
    right: 20px;
    top: 20px;
    color: #ffffff;
    font-size: 18px;
    cursor: pointer;
  }
  img {
    width: 34%;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
  }
`;
const Zoom = ({ img, setOpenPopup }) => {
  return (
    <ZoomWrapper>
      <i className="icon-close" onClick={() => setOpenPopup(false)} role="button" tabIndex="0" />
      <img src={img} alt="" />
    </ZoomWrapper>
  );
};

Zoom.propTypes = {
  img: PropTypes.string,
  setOpenPopup: PropTypes.func,
};

export default Zoom;
