import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import diffOperations from 'utils/diffOperations';

const Input = ({
  name,
  label,
  formik,
  setModelInfos,
  modelInfos,
  copiedElement,
  type = 'text',
  options = [],
}) => {
  const { [name]: value } = formik.values;
  const [isEnter, setIsEnter] = useState(false);
  const [isPasted, setIsPasted] = useState(false);
  const inputRef = useRef(null);

  const handlPaste = () => {
    const { name: fieldName } = inputRef.current;
    const data = { ...modelInfos, [fieldName]: copiedElement };
    setModelInfos(data);
    setIsPasted(true);
  };

  const pastWithV = () => {
    handlPaste();
  };

  const pastWithButton = async () => {
    handlPaste();
    inputRef.current.focus();
    const text = await navigator.clipboard.readText();
    formik.setFieldValue(name, text);
  };

  const handleOnBlur = event => {
    formik.handleBlur(event);
    if (copiedElement && copiedElement.baseString && isPasted) {
      const operations = diffOperations(copiedElement.baseString, value);
      const data = {
        ...modelInfos,
        [event.target.name]: {
          ...copiedElement,
          operations,
        },
      };
      setModelInfos(data);
      setIsPasted(false);
    }
  };

  const showError = field => {
    const {
      touched: { [field]: touchedField },
      errors: { [field]: errorField },
    } = formik;

    return touchedField && errorField ? <div className="form-error">{errorField}</div> : null;
  };

  if (type === 'radio') {
    return (
      <div className="form-radio-box">
        {options.map(option => (
          // eslint-disable-next-line jsx-a11y/no-static-element-interactions
          <div
            className="radio-box-container"
            key={option.key}
            onClick={() => formik.setFieldValue(name, option.value)}
          >
            <input
              type={type}
              id={option.key}
              name={name}
              value={option.value}
              checked={value === option.value} // wala
            />
            <span className="checkmark" />
            <label htmlFor={option.key}>{option.key}</label>
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className={`${showError(name) ? 'form-groupe error' : 'form-groupe'}`}>
      <label htmlFor={name}>{label}</label>
      <div
        className="input-content"
        onMouseEnter={() => setIsEnter(true)}
        onMouseLeave={() => setIsEnter(false)}
      >
        <input
          ref={inputRef}
          type={type}
          id={name}
          name={name}
          onChange={e => formik.setFieldValue(name, e.target.value.trimStart())}
          onBlur={handleOnBlur}
          value={value}
          onPaste={pastWithV}
        />
        {isEnter && (
          <i className="icon-paste" onClick={pastWithButton} role="button" tabIndex={0} />
        )}
      </div>
      {showError(name)}
    </div>
  );
};

Input.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  setModelInfos: PropTypes.func,
  copiedElement: PropTypes.object,
  modelInfos: PropTypes.object,
  formik: PropTypes.object,
  type: PropTypes.string,
  options: PropTypes.array,
};

export default Input;
