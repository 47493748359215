import React from 'react';
import { useLocation } from 'react-router-dom';
import ValidationWrapper from './ConfirmationWrapper';

const Confirmation = () => {
  const location = useLocation();

  return (
    <ValidationWrapper>
      <div className="card">
        <img src="../assets/images/Confirmation.svg" alt="confirmation" />
        <span>
          Votre {location?.state?.isBill ? 'facture' : 'devis'} a été{' '}
          {location?.state?.fromPage === 'garage_validation' ? 'envoyé' : 'validé'}
          {location?.state?.isBill && 'e'}
        </span>
      </div>
    </ValidationWrapper>
  );
};

export default Confirmation;
