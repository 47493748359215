import React from 'react';
import withStyle from './withProgressBar';

const ProgressBar = ({ className }) => (
  <div className={className}>
    <div className="progress-bar">
      <div className="progress-bar-fill" />
    </div>
  </div>
);

export default withStyle(ProgressBar);
