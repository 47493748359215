import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'formik';
import EditableTable from 'components/EditableTable';
import EditableBlock from 'components/EditableBlock';
import EditableInput from 'components/EditableInput';
import { colors } from 'static/theme';
import ValidationWrapper from './ValidationWrapper';

const columns = [
  'Type',
  'Référence',
  'Désignation',
  'Qté',
  'Px Unit',
  'Montant HT',
  'Montant TVA',
  'Montant TTC',
];

const cells = [
  { type: 'input', name: 'type' },
  { type: 'input', name: 'ref' },
  { type: 'textarea', name: 'designation' },
  { type: 'input', name: 'quantity' },
  { type: 'input', name: 'unitPrice' },
  { type: 'input', name: 'ht' },
  { type: 'input', name: 'tva' },
  { type: 'input', name: 'ttc' },
];

const Form = props => {
  const [editableBlock, setEditableBlock] = useState(null);
  const {
    handleSubmit,
    values,
    handleChange,
    errors,
    isValidating,
    isSubmitting,
    setFieldValue,
    isBill,
  } = props;

  const errorKeys = Object.keys(errors);

  if (isSubmitting && !isValidating && errorKeys.length !== 0) {
    let errorText = '';

    errorKeys.forEach(key => {
      errorText += `\n- ${errors[key]}`;
    });
    // handling errors will be changing, alert just for test
    alert(errorText);
  }

  return (
    <ValidationWrapper>
      <form onSubmit={handleSubmit}>
        <div className="validation__header">
          <div className="adress garage">
            <EditableBlock
              setBlock={setEditableBlock}
              block="garageInfo"
              editableBlock={editableBlock}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
            >
              <EditableInput
                label="Garage :"
                name="garageName"
                value={values.garageName}
                isBoldValue
              />
              <EditableInput label="Adresse :" name="garageAddress" value={values.garageAddress} />
              <EditableInput
                label="Code Postal :"
                name="garagePostalCode"
                value={values.garagePostalCode}
                isInline
              />
              <EditableInput label="Ville :" name="garageCity" value={values.garageCity} isInline />
            </EditableBlock>
          </div>
          <div className="validation__detail">
            <EditableBlock
              setBlock={setEditableBlock}
              block="clientInfo"
              editableBlock={editableBlock}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
            >
              <EditableInput label="Client :" name="clientName" value={values.clientName} />
              <EditableInput label="Adresse :" name="clientAddress" value={values.clientAddress} />
              <EditableInput
                label="Code Postal :"
                name="clientPostalCode"
                value={values.clientPostalCode}
                isInline
              />
              <EditableInput
                label="Ville : "
                name="clientCity"
                value={values.clientCity}
                isInline
              />
              <br />
              <EditableInput
                label="N° facture/devis :"
                name="invoiceNumber"
                value={values.invoiceNumber}
                isBoldLabel
              />
              <EditableInput
                label="Bon pour accord :"
                name="accord"
                value={values.accord}
                isBoldLabel
              />
              <EditableInput label="Marque :" name="brand" value={values.brand} isBoldLabel />
              <EditableInput label="Modèle :" name="model" value={values.model} isBoldLabel />
              <EditableInput
                name="licensePlate"
                label="Immatriculation :"
                value={values.licensePlate}
                isBoldLabel
              />
              <EditableInput
                name="kilometre"
                label="Kilométrage :"
                value={values.kilometre}
                isBoldLabel
              />
            </EditableBlock>
          </div>
        </div>
        <div className="validation__container">
          <FieldArray
            name="details"
            render={arrayHelpers => (
              <EditableTable
                arrayName="details"
                editableLines
                cells={cells}
                columns={columns}
                className="table_designation"
                values={values}
                arrayHelpers={arrayHelpers}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
              />
            )}
          />
          <div className="recap_block">
            <div className="recap_title">Recapitulatif</div>
            <div className="recap_content">
              <EditableBlock
                setBlock={setEditableBlock}
                block="recap"
                editableBlock={editableBlock}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
                customClassName={editableBlock === 'recap' ? 'editable_block-price' : undefined}
              >
                <EditableInput label="Total HT" name="totalHT" value={values.totalHT} />
                <EditableInput label="Total TVA" name="totalTVA" value={values.totalTVA} />
                <br />
                <EditableInput
                  color={colors.blue}
                  isBoldValue
                  isBoldLabel
                  label="Total TTC"
                  name="totalTTC"
                  value={values.totalTTC}
                />
              </EditableBlock>
            </div>
          </div>
        </div>
        <div className="validation__footer">
          <div className="date">
            <p>Sous réserve de démontage</p>
            <EditableBlock
              setBlock={setEditableBlock}
              placeholder="JJ"
              block="day"
              editableBlock={editableBlock}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
            >
              <EditableInput name="day" value={values.day} isInline isBoldValue />
              <span>&#47;</span>
              <EditableInput
                placeholder="MM"
                name="month"
                value={values.month}
                isInline
                isBoldValue
              />
              <span>&#47;</span>
              <EditableInput
                placeholder="AAAA"
                name="year"
                value={values.year}
                isInline
                isBoldValue
              />
            </EditableBlock>
          </div>
        </div>
        <button className="submit" type="submit">
          <img src="/assets/images/Check_icon.svg" alt="validation" />
          {isBill ? "Valider l'ensemble de la facture" : "Valider l'ensemble du devis"}
        </button>
      </form>
    </ValidationWrapper>
  );
};

Form.propTypes = {
  values: PropTypes.object,
  errors: PropTypes.object,
  handleSubmit: PropTypes.func,
  handleChange: PropTypes.func,
  setFieldValue: PropTypes.func,
  isValidating: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  isBill: PropTypes.bool,
};

export default Form;
