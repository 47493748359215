import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import WithDropZoneWrapper from './DropZoneWrapper';
import ProgressBar from '../ProgressBar';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 1,
  borderRadius: 2,
  borderColor: '#c3d0e9',
  borderStyle: 'solid',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  transition: 'border .24s ease-in-out',
};

const activeStyle = {
  borderColor: '#2196f3',
  borderWidth: 2,
};

const acceptStyle = {
  borderColor: '#00e676',
  borderWidth: 2,
  borderStyle: 'dashed',
};

const rejectStyle = {
  borderColor: '#ff1744',
  borderWidth: 2,
  borderStyle: 'dashed',
};

function DropZone({ fileInput, setFileInput, className, percent }) {
  const onDrop = useCallback(acceptedFiles => {
    setFileInput(acceptedFiles[0]);
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    fileRejections,
    isDragAccept,
    isDragReject,
    open,
  } = useDropzone({
    accept: 'application/pdf',
    noClick: true,
    noKeyboard: true,
    maxFiles: 1,
    onDrop,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept],
  );

  const handleClose = () => {
    setFileInput(null);
  };

  return (
    <div className={className}>
      <h3>Déposer votre devis pour envoi au conducteur *</h3>
      <div className="dropzone" {...getRootProps({ style })}>
        <img src="/assets/images/cloud.svg" alt="" />
        <input {...getInputProps()} />
        {!fileInput?.name && isDragActive ? (
          <p> {isDragAccept ? 'Déposez votre devis ici ... ' : 'Type de fichier non accepté'}</p>
        ) : (
          <>
            {fileInput?.name ? (
              <div className="file_droped">
                <p className="file_name">
                  {fileInput?.name.length < 20
                    ? fileInput?.name
                    : `${fileInput?.name.substring(0, 20)} ...`}
                </p>
                <img src="/assets/images/close.svg" alt="" onClick={handleClose} />
              </div>
            ) : (
              <>
                {fileRejections[0] && <span>Fichier invalid</span>}
                <p>Déposez ici</p>
              </>
            )}
            <p>Ou</p>
            <button className="dropzone_button" type="button" onClick={open}>
              Parcourir
            </button>
          </>
        )}
        <ProgressBar percent={percent} />
      </div>
      <em>Au format PDF uniquement</em>
    </div>
  );
}

DropZone.propTypes = {
  className: PropTypes.string,
  fileInput: PropTypes.object,
  setFileInput: PropTypes.func,
  percent: PropTypes.number,
};

export default WithDropZoneWrapper(DropZone);
