/* eslint-disable no-await-in-loop */
import React, { useState, useEffect } from 'react';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
} from 'chart.js';
import { Doughnut, Bar } from 'react-chartjs-2';
import { db } from 'firebase.js';
import Wrapper from './Wrapper';

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title);

const criticalFields = [
  'totalHT',
  'totalTVA',
  'totalTTC',
  'licensePlate',
  'invoiceNumber',
  'date',
  'accord',
  'garageName',
  'clientName',
  'garageAddress',
  'clientAddress',
  'garagePostalCode',
  'clientPostalCode',
];

const getData = async () => {
  const result = [];
  let koIndocs = [];
  const documentCollection = await db
    .collection('documentModels')
    .orderBy('creationDate', 'asc')
    .get();

  const trainedModels = documentCollection.docs.filter(
    doc => doc.data().handledDocuments !== undefined,
  );

  // eslint-disable-next-line no-restricted-syntax
  for (const doc of trainedModels) {
    const { id: modelId } = doc;
    const documents = await db
      .collection('documents')
      .where('modelId', '==', modelId)
      .get();

    const koData = documents.docs
      .filter(
        currentDoc => currentDoc.data().isPreValidate && currentDoc.data().isRejected === false,
      )
      .map(row => row.data().criticalFieldKo || []);

    koIndocs = [...koIndocs, ...koData];

    const editionByFields = criticalFields.map(f => {
      const modelData = doc.data();

      const number = modelData[f].filter(row => row.origin === 'prevalidation').length - 1;

      return {
        field: f,
        number,
        id: doc.id,
      };
    });

    const percentOfErrors = editionByFields
      .filter(f => f.number !== -1)
      .map(f => (f.number * 100) / documents.docs.length);

    const errInPercent =
      percentOfErrors.length !== 0
        ? percentOfErrors.reduce((a, v) => a + v, 0) / percentOfErrors.length
        : 0;

    const currentDetails = {
      modelId: doc.id,
      numberOfEditions: doc.data().divergence.length,
      numberOfDocuments: documents.docs.length,
      errInPercent,
      editionByFields,
      documents: documents.docs.map(r => r.data().imagesUrls[0].url),
    };

    result.push(currentDetails);
  }

  const koPercentIndDocs = criticalFields.map(
    field => ((koIndocs.filter(koInDoc => koInDoc?.includes(field))?.length || 0) * 100) / koIndocs.length,
  );

  return {
    numberOfModels: documentCollection.docs.length,
    nbOfTrainedModels: trainedModels.length,
    data: result.sort((a, b) => b.numberOfDocuments - a.numberOfDocuments),
    koPercentIndDocs,
    koIndocs,
    prevalidateDocs: koPercentIndDocs.length,
  };
};

const AllfleetStat = () => {
  const [data, setData] = useState({});
  const { koIndocs } = data;

  const barChartData = {
    labels: criticalFields,
    datasets: [
      {
        label: 'pourcentage des erreurs par champs %',
        data: data.koPercentIndDocs || [],
        backgroundColor: '#1e3c8194',
      },
    ],
  };

  useEffect(() => {
    async function fetchData() {
      const result = await getData();
      setData(result);
    }

    fetchData();
  }, []);

  return (
    <Wrapper>
      <div className="doughnuts">
        <div className="graph">
          {data.data && (
            <>
              <p className="legend">
                {data.data.length} modèle{data.data.length < 2 ? '' : 's'}
              </p>
              <Doughnut
                options={{
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                }}
                data={{
                  labels: data.data ? data.data.map(row => row.modelId) : [],
                  datasets: [
                    {
                      label: 'Nombre des documents par modèle',
                      data: data.data ? data.data.map(row => row.numberOfDocuments) : [],
                      backgroundColor: [
                        '#1c2c4f',
                        '#112961',
                        '#143889',
                        '#1E3C81',
                        '#4364af',
                        '#6986c8',
                        '#87a0d8',
                      ],
                      borderColor: [
                        '#1c2c4f',
                        '#112961',
                        '#143889',
                        '#1E3C81',
                        '#4364af',
                        '#6986c8',
                        '#87a0d8',
                      ],
                      borderWidth: 1,
                    },
                  ],
                }}
              />
            </>
          )}
        </div>
        <div className="graph">
          {data.data && (
            <Doughnut
              data={{
                labels: ['OK', 'KO'],
                datasets: [
                  {
                    label: 'ok/ko',
                    data: koIndocs
                      ? [
                          koIndocs.filter(row => row.length === 0).length,
                          koIndocs.filter(row => row.length !== 0).length,
                        ]
                      : [],
                    backgroundColor: ['#48c466', '#e29122'],
                    borderColor: ['#48c466', '#e29122'],
                    borderWidth: 1,
                  },
                ],
              }}
            />
          )}
        </div>
      </div>
      <div className="bar">
        <Bar
          options={{
            responsive: true,
            plugins: {
              legend: {
                position: 'top',
              },
              title: {
                display: true,
                text: 'Moyenne des erreurs par champs',
              },
            },
            showLines: true,
            scales: {
              y: {
                max: 100,
                min: 0,
              },
            },
          }}
          data={barChartData}
        />
        ;
      </div>
      <div className="models">
        {data?.data?.map(row => (
          <div className="model">
            <p>
              {row.modelId}: {row.numberOfDocuments}
            </p>
            <div className="model-container">
              {row.documents.slice(0, 5).map(img => (
                <img src={img} alt="" />
              ))}
            </div>
          </div>
        ))}
      </div>
    </Wrapper>
  );
};

export default AllfleetStat;
