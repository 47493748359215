/* eslint-disable no-alert */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from 'react';
import config from 'config';
import TestWrapper from './TestWrapper';

const ModelsTest = () => {
  const [selected, setSelected] = useState(null);
  const [matchs, setMatchs] = useState([]);
  const [data, setData] = useState([]);
  const [nbOfClicks, setNbOfClicks] = useState(0);
  const [loading, setLoading] = useState(false);

  const models = data.length > 0 ? data.filter(({ type }) => type === 'model') : [];
  const tests = data.length > 0 ? data.filter(({ type }) => type === 'test') : [];

  const filtredMatchsById = id => matchs.filter(m => m.model === id);
  const handleClick = t => {
    setSelected(t);
  };

  const handleEmptyClick = () => {
    setNbOfClicks(nbOfClicks + 1);
  };

  const isMatching = id => {
    if (matchs.length === 0) return false;

    const matchedAlgos = filtredMatchsById(id);
    if (matchedAlgos.length === 1 && matchedAlgos[0].score < 1) return false;

    const ids = matchs.map(match => match.model);

    return ids.includes(id);
  };

  const matchingColor = id => {
    const m = filtredMatchsById(id);
    const score = m.reduce((acc, current) => acc + current.score, 0);

    const otherMatchs = matchs.filter(o => o.model !== id);
    if (otherMatchs.length === 0) return '#65dc1a';
    if (otherMatchs.length === 1) {
      if (score > otherMatchs[0].score) return '#65dc1a';

      return 'orange';
    }

    if (otherMatchs[0].model === otherMatchs[1].model) {
      const concurentScore = otherMatchs.reduce((acc, current) => acc + current.score);
      if (score > concurentScore) return '#65dc1a';

      return 'orange';
    }

    const otherScore = Math.max(...otherMatchs.map(o => o.diff));
    if (score > otherScore) return '#65dc1a';

    return 'orange';
  };

  const interpretation = id => {
    const matching = matchs.filter(match => match.model === id);
    const numberOfMatchs = matching.length;
    const matchersNames = matching.map(match => match.name).join(' et ');

    return (
      <div className="interpretation">
        <p>
          Ce modèle a été apparié <b>{numberOfMatchs}</b> fois: <b>{matchersNames}</b>
        </p>
        {matching.map((m, i) => (
          <ul key={i}>
            <li>
              Algo:
              <b>{m.name}</b>
            </li>
            <li>
              Nombre des points gagnés:
              <b>{m.score}</b>
            </li>
            <li>
              Pourcentage de corréspendance des mots clés: <b>{m.matchedKeysPercent}</b>
            </li>
            <li>
              Différence: <b>{m.diff}</b>
            </li>
            <hr />
          </ul>
        ))}
      </div>
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const result = await fetch(`${config.managerUrl}/models/test`);
      const resultToJson = await result.json();
      setData(resultToJson);
      setLoading(false);
    };

    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const res = await fetch(`${config.managerUrl}/models/findComputedModel`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: JSON.stringify(selected),
      });
      const result = await res.json();
      setMatchs(result);
      setLoading(false);
      if (result.length === 0) alert('Aucun modele correspond à ce document');
    }

    if (selected?.id) fetchData();
  }, [selected]);

  useEffect(() => {
    const el = document.querySelector('.match');
    if (el) {
      window.scrollTo({
        top: el.offsetTop,
        behavior: 'smooth',
      });
    }
  }, [matchs]);

  return nbOfClicks === 2 ? (
    <TestWrapper>
      <h1>Les modèles</h1>
      <div className="container">
        {models.map(model => (
          <div
            key={model.id}
            className={isMatching(model.id) ? 'card match' : 'card'}
            style={{ background: isMatching(model.id) ? matchingColor(model.id) : 'white' }}
          >
            <img className="document" src={model.imagesUrl.url} alt="" />
            <div className="signs">
              {model.signUrls.map(s => (
                <div key={s.url}>
                  <p>{s.name.split('.')[0]}</p>
                  <img src={s.url} alt="" />
                </div>
              ))}
              {isMatching(model.id) && interpretation(model.id)}
            </div>
          </div>
        ))}
      </div>
      <h1>Les cas de test</h1>
      <div className="container">
        {tests.map(t => (
          <button
            key={t.id}
            className={selected?.id === t.id ? 'card selected-card' : 'card'}
            onClick={() => handleClick(t)}
            type="button"
            style={{ background: selected?.id === t.id ? '#00a1ff' : '#434a4f1c' }}
          >
            <img className="document" src={t.imagesUrl.url} alt="" />
            <div className="signs">
              {t.signUrls.map(s => (
                <div key={s.url}>
                  <p>{s.name.split('.')[0]}</p>
                  <img src={s.url} alt="" />
                </div>
              ))}
            </div>
          </button>
        ))}
      </div>
      {loading && (
        <div className="loading">
          <p>Loading ... </p>
        </div>
      )}
    </TestWrapper>
  ) : (
    <div style={{ height: 'calc(100vh - 160px)' }} onClick={handleEmptyClick} />
  );
};

export default ModelsTest;
