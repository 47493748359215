import React, { useEffect, useState } from 'react';
import config from 'config';
import { useLocation } from 'react-router-dom';
import HeaderWrapper from './HeaderWrapper';

const Header = () => {
  const [logo, setLogo] = useState(null);
  const { pathname } = useLocation();
  const refId = pathname.split('/')[pathname.split('/').length - 1];

  useEffect(() => {
    if (pathname.split('/').includes('quotation')) {
      const fetchLogo = async () => {
        const result = await fetch(`${config.managerUrl}/brand/logo/${refId}`);
        const data = await result.json();
        if (data.url) setLogo(data.url);
        else setLogo('/assets/images/aag-logo.png');
      };

      fetchLogo();
    } else {
      setLogo('/assets/images/aag-logo.png');
    }
  }, []);

  return (
    logo && (
      <HeaderWrapper>
        <img src={logo} alt="logo" />
      </HeaderWrapper>
    )
  );
};

export default Header;
