const colors = {
  blue: '#1E3C81',
  white: 'white',
  green: '#009E0B',
  grey: '#E8E8E8',
  RaisinBlack: '#262626 ',
  orange: '#d35039',
  bayOfMany: '#1E3C81',
  mineShaft: '#2E2E2E',
  concrete: '#F2F2F2',
  codGray: '#161616',
  alto: '#E0E0E0',
  SunsetOrange: '#ff4d4d',
};

const breakPoints = {
  desktop: {
    minWidth: 1024,
  },
  tablet: {
    minWidth: 768,
    maxWidth: 1023.5,
  },
  mobile: {
    maxWidth: 767.5,
    minWidth: 320,
  },
};

const customScroll = `
  /* width */
  ::-webkit-scrollbar {
    width: 6px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 3px #E0E0E0; 
    border-radius: 3px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #8C8C8C; 
    border-radius: 3px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(140, 140, 140, .7);
  }
`;

export { colors, breakPoints, customScroll };
