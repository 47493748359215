import React, { useState } from 'react';
import PropTypes from 'prop-types';
import config from 'config';
import viewConstant from 'utils/view.constant';
import withModalStyle from './withModalStyle';

function Modal({ className, setOpen, data, setView }) {
  const { NEW_QUOTE, QUOTE_REFUSED } = viewConstant;
  const [comment, setComment] = useState('');
  const sendRequestNewQoute = async () => {
    const {
      licensePlate,
      garagePhone,
      documentInfo: { documentUrl, fileName },
      refId,
    } = data;
    const res = await fetch(`${config.managerUrl}/documents/sendRequestNewQuote`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
      body: JSON.stringify({
        clientComment: comment,
        licensePlate,
        documentUrl,
        fileName,
        garagePhone,
        refId,
      }),
    });
    const { error } = await res.json();
    if (!error) {
      setOpen(false);
      setView(NEW_QUOTE);
    }
  };

  const refuseQuote = async () => {
    const {
      licensePlate,
      garagePhone,
      totalTTC,
      documentInfo: { documentUrl, fileName },
      refId,
    } = data;
    const res = await fetch(`${config.managerUrl}/documents/refuseQuote`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
      body: JSON.stringify({
        clientComment: comment,
        licensePlate,
        documentUrl,
        fileName,
        garagePhone,
        totalTTC,
        refId,
      }),
    });
    const { error } = await res.json();
    if (!error) {
      setOpen(false);
      setView(QUOTE_REFUSED);
    }
  };

  return (
    <div className={className}>
      <div className="modal_content">
        <i className="icon-close" onClick={() => setOpen(false)} role="button" tabIndex="0" />
        <div className="Modal_text">
          Afin de vous proposer un meilleur service, merci de nous communiquer les raisons de votre
          refus.
        </div>
        <textarea
          id="message"
          name="message"
          placeholder="Exemple : Je souhaite faire une partie de ces réparations"
          value={comment}
          onChange={e => setComment(e.target.value)}
        />
        <div className="Modal_text">
          Voulez-vous que le garage <b>{data?.garageName}</b> vous propose un nouveau devis tenant
          compte vos remarques ?
        </div>
        <div className="buttons">
          <button type="button" className="send" onClick={sendRequestNewQoute}>
            OUI, ENVOYEZ-MOI UN AUTRE DEVIS
          </button>
          <button type="button" className="refuse" onClick={refuseQuote}>
            Non, je souhaite récupérer mon véhicule en l&apos;état
          </button>
        </div>
      </div>
    </div>
  );
}

Modal.propTypes = {
  data: PropTypes.object,
  className: PropTypes.string,
  setOpen: PropTypes.func,
  setView: PropTypes.func,
};

export default withModalStyle(Modal);
