import { get, map } from 'lodash';

export default data => ({
  garageName: get(data, 'data.garageName', '')?.trim(),
  garageAddress: get(data, 'data.garageAddress', '')?.trim(),
  garagePostalCode: get(data, 'data.garagePostalCode', '')?.trim(),
  garageCity: get(data, 'data.garageCity', '')?.trim(),
  garagePhone: get(data, 'data.garagePhone', '')?.trim(),
  garageEmail: get(data, 'data.garageEmail', '')?.trim(),
  garageWebSite: get(data, 'data.garageWebSite', '')?.trim(),
  clientName: get(data, 'data.clientName', '')?.trim(),
  clientAddress: get(data, 'data.clientAddress', '')?.trim(),
  clientPostalCode: get(data, 'data.clientPostalCode', '')?.trim(),
  clientCity: get(data, 'data.clientCity', '')?.trim(),
  licensePlate: get(data, 'data.licensePlate', '')?.trim(),
  invoiceNumber: get(data, 'data.invoiceNumber', '')?.trim(),
  isBill: get(data, 'isBill'),
  accord: get(data, 'data.accord', data.isBill ? '' : '99')?.trim(),
  brand: get(data, 'data.brand', '')?.trim(),
  model: get(data, 'data.model', '')?.trim(),
  kilometre: get(data, 'data.kilometre', '')?.trim(),
  motorization: get(data, 'data.motorization', '')?.trim(),
  details: map(
    get(data, 'data.details', [
      {
        type: get(data, 'type', '')?.trim(),
        ref: get(data, 'ref', '')?.trim(),
        designation: get(data, 'designation', '')?.trim(),
        quantity: get(data, 'quantity', ''),
        unitPrice: get(data, 'unitPrice', ''),
        ht: get(data, 'ht', ''),
        tva: get(data, 'tva', ''),
        ttc: get(data, 'ttc', ''),
      },
    ]),
    row => ({
      type: get(row, 'type', '').trim(),
      ref: get(row, 'ref', '').trim(),
      designation: get(row, 'designation', '')?.trim(),
      quantity: get(row, 'quantity', ''),
      unitPrice: get(row, 'unitPrice', ''),
      ht: get(row, 'ht', ''),
      tva: get(row, 'tva', ''),
      ttc: get(row, 'ttc', ''),
    }),
  ),
  totalHT: get(data, 'data.totalHT', '')?.trim(),
  totalTVA: get(data, 'data.totalTVA', '')?.trim(),
  totalTTC: get(data, 'data.totalTTC', '')?.trim(),
  date: get(data, 'data.date', ''),
  day: get(data, 'data.day', ''),
  month: get(data, 'data.month', ''),
  year: get(data, 'data.year', ''),
});
