import styled from 'styled-components';

export default (Component) => styled(Component)`
  display: flex;
  flex-direction: column;
  align-items: center;
  .section {
    width: 520px;
    text-align: left;
    .thanks {
      margin-top: 71px;
      color: #50B154;
      font-size: 18px;
      font-weight: bold;
      line-height: 22px;
      text-align: center;
    }
    .message {
      width: 100%;
      margin-top: 40px;
      p {
        &.cordially {
          margin-top : 20px;
        }
        color: #000000;
        font-size: 16px;
        line-height: 40px;
        &:last-child {
          font-weight: 600;
          margin-top: 5px;
        }
      }
      .contact-info {
        line-height: 30px;
      }
    }
  }
  @media (max-width: 768px) {
    .section {
      width: 90%;
    }
  }
`;