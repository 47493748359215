import React, { useEffect, useState } from 'react';
import { useLocation  } from 'react-router-dom';
import config from 'config';
import RGPDWrapper from './RGPDWrapper';
import Loader from 'components/Loader';

function RGPD({ className }) {
  const [RGPD, setRGPD] = useState("")
  const { pathname } = useLocation();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const fetchRGPD = async () => {
      const path = pathname.split('/')[1];
      const result = await fetch(`${config.managerUrl}/brand/path/${path}`);
      const data = await result.json();
      if (!data?.error && data?.data?.rgpd) {
        setRGPD(data.data.rgpd);
        setLoading(false);
      }
    }
    fetchRGPD()
  })
  return (
    <div className={className}>
      <div className="title">Charte de protection des données.</div>
      { 
        isLoading ? <Loader /> : (
          <div  dangerouslySetInnerHTML={{__html: RGPD}} />
        )
      }
    </div>
  )
}

export default RGPDWrapper(RGPD);
