import firebase from '../firebase';

export default async (file, setPercent) =>
  new Promise((resolve, reject) => {
    const now = Date.now();
    const path = `documents/CheckAuto`;

    const storage = firebase.storage();

    const storageRef = storage.ref(`${path}/${now}`);

    const uploadTask = storageRef.put(file, { contentType: file.type });

    uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      snapshot => {
        setPercent((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
      },
      error => {
        console.error('error', error);
        reject(error);
      },
      async () => {
        const url = await uploadTask.snapshot.ref.getDownloadURL();
        resolve(url);
      },
    );
  });
