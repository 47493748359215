import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const ModalConfirmWrapper = styled.div`
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1000;
  .popup {
    text-align: center;
    background: #ffffff;
    padding: 20px;
    border-radius: 3px;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    span {
      display: block;
    }
    .cta {
      margin-top: 30px;
      display: flex;
      justify-content: center;
      button {
        border: 1px solid;
        border-radius: 2px;
        padding: 10px 20px;
        cursor: pointer;
        font-weight: 600;
        font-size: 14px;
        margin-left: 20px;

        &.cancel {
          border-color: #d0021b;
          background: #ffffff;
          color: #d0021b;
        }
        &.confirm {
          border: none;
          background: #1e3c81;
          color: #ffffff;
        }
      }
    }
  }
`;

const ModalConfirm = ({ message, setOpenPopup, callback }) => {
  return (
    <ModalConfirmWrapper>
      <div className="popup">
        <span>{message}</span>
        <div className="cta">
          <button type="button" className="cancel" onClick={() => setOpenPopup(false)}>
            Non, annuler
          </button>
          <button
            type="button"
            className="confirm"
            onClick={() => {
              callback();
              setOpenPopup(false);
            }}
          >
            Oui, continuer
          </button>
        </div>
      </div>
    </ModalConfirmWrapper>
  );
};

ModalConfirm.propTypes = {
  message: PropTypes.string,
  setOpenPopup: PropTypes.func,
  callback: PropTypes.func,
};

export default ModalConfirm;
