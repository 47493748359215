import styled from 'styled-components';

export default (Component) => styled(Component)`  
  background: rgba(0,0,0,.5);
  position: fixed; 
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .modal_content {
    position: relative;
    height: 388px;
    width: 741px;
    border-radius: 4px;
    background-color: #FFFFFF;
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);
    padding: 30px;
    i {
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
      color: #1E3C81;
    }
  }
  .Modal_text {
    color: #161616;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
  }
  textarea {
    margin-top: 15px;
    margin-bottom: 38px;
    width: 94%;
    height: 127px;
    resize: none;
    border-radius: 4px;
    border: 2px solid #8C8C8C;
    padding: 20px;
  
    font-family: Montserrat;
    &::placeholder {
      color: #8C8C8C;
      font-size: 14px;
    }
  }
  .buttons {
    margin-top: 26px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .send{
      height: 36px;
      border-radius: 2px;
      border: none;
      background-color: #50B154;
      color: #FFFFFF;
      font-size: 14px;
      font-weight: 600;
      line-height: 18px;
      cursor: pointer;
      width: 45%;
    }
    .refuse {
      height: 36px;
      width: 45%;
      color: #D0021B;
      border: none;
      background: transparent;
      cursor: pointer;
    }
  }
  @media (max-width: 768px) {
    .modal_content {
      width: 75%;
      .Modal_text {
        font-size: 14px;
      }
      textarea {
        margin-top: 10px;
        margin-bottom: 20px;
        width: 85%;
      }
      .buttons {
        margin-top: 15px;
        .send {
          width: 50%;
          font-size: 11px;
        }
        .refuse {
          width: 50%;
          font-size: 11px;
        }
      }
    }
  }
`;