/* eslint-disable no-underscore-dangle */
import React, { useState, useContext, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { PopupContext } from 'context/PopupContext';
import HistoryStyle from './HistoryStyle';
import Zoom from './Zoom';
import ModalConfirm from './ModalConfirm';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const History = ({ className, current, divergence }) => {
  const history = useHistory();
  const { pathname } = history.location;
  const [slide, setSlide] = useState(0);
  const { setComponent, setOpenPopup } = useContext(PopupContext);
  const slider = useRef();

  const handleZoom = img => {
    setOpenPopup(true);
    setComponent(<Zoom setOpenPopup={setOpenPopup} img={img} />);
  };

  const previous = () => {
    slider.current.slickPrev();
  };

  const next = () => {
    slider.current.slickNext();
  };

  const createModelHandler = () => {
    setOpenPopup(true);
    const message = 'Êtes vous sûr de vouloir créer un nouveau modèle';
    setComponent(
      <ModalConfirm
        setOpenPopup={setOpenPopup}
        message={message}
        callback={() => history.replace(pathname, 'new')}
      />,
    );
  };

  const resetModelHandler = () => {
    setOpenPopup(true);
    const message = 'Êtes vous sûr de vouloir réinitialiser ce modèle';
    setComponent(
      <ModalConfirm
        setOpenPopup={setOpenPopup}
        message={message}
        callback={() => history.replace(pathname, 'reset')}
      />,
    );
  };

  const confirmModelHandler = () => {
    setOpenPopup(true);
    const message = 'Êtes vous sûr de vouloir confirmer ce modèle';
    setComponent(
      <ModalConfirm
        setOpenPopup={setOpenPopup}
        message={message}
        callback={() => history.replace(pathname, 'ok')}
      />,
    );
  };

  const slideSettings = {
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    afterChange(index) {
      setSlide(index);
    },
  };

  return (
    <div className={className}>
      <h1 className="title">Veuillez vérifier le comportement de votre modèle</h1>
      <div className="models">
        <div className="model_content">
          <span>Les divergences du modèles </span>
          <div className="model_image">
            <Slider ref={slider} {...slideSettings}>
              {divergence
                .sort((a, b) => b.creationDate - a.creationDate)
                .slice(0, 4)
                .map(el => (
                  <img key={el.image} src={el.image} alt="divergence" />
                ))}
            </Slider>
            <button
              type="button"
              className="zoom_in"
              onClick={() => handleZoom(divergence[slide].image)}
            >
              <i className="icon-zoom-in" />
            </button>
            <div className="model_info">
              <em>{new Date(divergence[slide].creationDate).toLocaleDateString()}</em>
            </div>
          </div>
          <div className="chevrons">
            <button onClick={previous} type="button">
              <i className="icon-arrow-left" />
            </button>
            <button onClick={next} type="button">
              <i className="icon-arrow-right" />
            </button>
          </div>
        </div>
        <div className="model_content">
          <span>Document actuel</span>
          <div className="model_image">
            <img src={current} alt="edit your content" />
            <button type="button" className="zoom_in" onClick={() => handleZoom(current)}>
              <i className="icon-zoom-in" />
            </button>
            <div className="model_info">{/* <em>{new Date(date).toLocaleDateString()}</em> */}</div>
          </div>
        </div>
      </div>
      <div className="CTA">
        <button type="button" className="create_model" onClick={createModelHandler}>
          CRÉER UN NOUVEAU MODÉLE
        </button>
        <button type="button" className="reset_model" onClick={resetModelHandler}>
          RÉINITIALISER LE MODÉLE
        </button>
        <button type="button" className="confirm_model" onClick={confirmModelHandler}>
          CONFIRMER CE MODÉLE
        </button>
      </div>
    </div>
  );
};

History.propTypes = {
  className: PropTypes.string,
  current: PropTypes.string,
  date: PropTypes.object,
  divergence: PropTypes.array,
};

export default HistoryStyle(History);
