import styled from 'styled-components';

const ValidationWrapper = styled.div`
  position: absolute;
  top: 336px;
  left: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  .card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 171px;
    width: 402px;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);

    img {
      margin-bottom 20px;
    }
  
    span {
      height: 18px;
      color: #009e0b;
      font-family: Montserrat;
      font-size: 14px;
      font-weight: bold;
      line-height: 18px;
      text-align: right;
    }
  }
`;

export default ValidationWrapper;
