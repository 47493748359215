import styled from 'styled-components';

export default Component => styled(Component)`
  margin: 30px auto 50px;
  width: 500px;
  h2 {
    text-align: center;
    size: 24px;
    font-weight: 600;
    color: #1e3c81;
  }
  .media-list {
    margin-top: 33px;
    a {
      display: block;
      text-align: left;
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      text-decoration: none;
      img {
        margin-right: 7px;
      }
    }
  }
  .form,
  .totalTTC {
    margin-top: 22px;
    color: #232324;
    .form-groupe {
      &.error {
        input {
          border: 1px solid #e7280b;
          border-radius: 2px;
        }
        label {
          color: #e7280b;
        }
      }
      label {
        display: inline-block;
        margin: 16px 0;
        font-weight: 600;
      }
      em {
        font-size: 10px;
        margin-left: 7px;
      }
      input {
        display: block;
        width: 100%;
        height: 47px;
        padding: 15px;
        box-sizing: border-box;
        font-weight: 600;
        font-size: 14px;
      }
      .form-error {
        margin-left: 5px;
        margin-top: 3px;
        font-size: 14px;
        color: #e7280b;
      }
    }
  }
  .comment {
    .form-groupe {
      label {
        display: block;
        margin: 16px 0;
        font-weight: 600;
        em {
          font-size: 10px;
          margin-left: 7px;
        }
      }
      textarea {
        display: block;
        width: 100%;
        padding: 15px;
        box-sizing: border-box;
        font-weight: 600;
        font-size: 14px;
      }
    }
  }
  .submit_button {
    height: 29px;
    width: 240px;
    padding: 7px, 23px;
    background-color: #1e3c81;
    color: #ffffff;
    border: none;
    font-weight: 600;
    margin: 32px 0 15px;
    cursor: pointer;
    &[disabled] {
      cursor: not-allowed;
      background: #dcdcdc;
      z-index: 1000;
      color: #ababab;
    }
  }
  .error-submitting {
    font-size: 14px;
    color: #e7280b;
    display: block;
    margin-bottom: 5px;
  }
  .warning {
    font-family: Montserrat;
    font-style: italic;
    font-weight: normal;
    font-size: 10px;
    color: #e7280b;
    display: block;
  }
`;
