import styled from 'styled-components';

const Wrapper = styled.div.attrs(({ item }) => ({
  style: {
    top: `${item.top * 100}%`,
    left: `${item.left * 100}%`,
    width: `${item.width * 100}%`,
    height: `${item.height * 100}%`,
  },
}))`
  position: absolute;
  background: rgba(0, 0, 0, 0.2);
  cursor: pointer;
  &:hover {
    border: 2px dashed red;
  }
`;

export default Wrapper;
