import styled from 'styled-components';

const LoaderWrapper = styled.div`
  .loader {
    margin: 0 0 2em;
    height: 300px;
    width: 50%;
    text-align: center;
    padding: 1em;
    margin: 0 auto 1em;
    display: inline-block;
    vertical-align: top;
    width: 100%;
    top: 200px;
    position: relative;
  }

  /*
    Set the color of the icon
  */
  svg path,
  svg rect {
    fill: #1e3c81;
  }
`;
export default LoaderWrapper;
