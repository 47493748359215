import React, { useState } from 'react';
import config from 'config';
import PopupWrapper from './PopupWrapper';
import { reasonsRejection, missingInformation } from './InitialReason';

const Popup = ({ setOpenPopup, token }) => {
  const handleSubmit = async () => {
    const res = await fetch(`${config.managerUrl}/documents/reject/${token}`, {
      method: 'PUT',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
      body: JSON.stringify({ reasonsRejection, missingInformation, freeComment }),
    });
    if (errors === '') {
      setOpenPopup(false);
    }

    const { error } = await res.json();
    if (!error && errors === '') {
      window.location.reload(false);
    }
  };

  const [checkedReasonRejection, setCheckedReasonRejection] = useState(
    new Array(reasonsRejection.length).fill(false),
  );
  const [checkedMissingInformation, setCheckedMissingInformation] = useState(
    new Array(missingInformation.length).fill(false),
  );
  const [freeComment, setFreeComment] = useState('');
  const [isEmptyInformation, setIsEmptyInformation] = useState(true);
  const [errors, setErrors] = useState('Veuillez sélectionner au moins un motif');

  const handleOnChange = position => {
    const updatedCheckedReasonRejection = checkedReasonRejection.map((item, index) =>
      index === position ? !item : item,
    );
    setCheckedReasonRejection(updatedCheckedReasonRejection);
    reasonsRejection[position].checked = updatedCheckedReasonRejection[position];
    const checker = updatedCheckedReasonRejection.every(v => v === false);
    if (!checker) {
      setErrors('');
    } else {
      setErrors('Veuillez sélectionner au moins un motif');
    }
  };

  const onChange = position => {
    const updatedMissingInformation = checkedMissingInformation.map((item, index) =>
      index === position ? !item : item,
    );
    setCheckedMissingInformation(updatedMissingInformation);
    missingInformation[position].checked = updatedMissingInformation[position];
    setIsEmptyInformation(updatedMissingInformation.every(v => v === false));
  };

  return (
    <PopupWrapper>
      <div className="behind-popup" onClick={() => setOpenPopup(false)} />
      <div className="pop_up_content">
        <div className="popup-body">
          <div className="popup-body__text-block bold">
            <label className="title">
              Motifs du rejet:<i>Veuillez sélectionner au moins un motif</i>
            </label>
            <div className="reasons">
              {reasonsRejection.map(({ name, label }, index) => {
                return (
                  <label htmlFor={`reasons-rejection-${index}`}>
                    <input
                      type="checkbox"
                      id={`reasons-rejection-${index}`}
                      name={name}
                      value={name}
                      checked={checkedReasonRejection[index]}
                      onChange={() => handleOnChange(index)}
                    />

                    {label}
                  </label>
                );
              })}
              {checkedReasonRejection[2] && (
                <div className="missingInformation">
                  {missingInformation.map(({ name, label }, index) => {
                    return (
                      <label htmlFor={`missing-information-${index}`}>
                        <input
                          type="checkbox"
                          id={`missing-information-${index}`}
                          name={name}
                          value={name}
                          checked={checkedMissingInformation[index]}
                          onChange={() => onChange(index)}
                        />
                        {label}
                      </label>
                    );
                  })}
                </div>
              )}
            </div>
            <textarea
              name="free_comment"
              rows="3s"
              value={freeComment}
              onChange={e => setFreeComment(e.target.value)}
              placeholder="Commentaire libre sur le rejet"
            />
            <button
              type="submit"
              disabled={(checkedReasonRejection[2] && isEmptyInformation) || errors !== ''}
              className="popup-button-rejet"
              onClick={() => handleSubmit()}
            >
              Valider le rejet
            </button>
            <span className="errors">{errors}</span>
          </div>
        </div>
      </div>
    </PopupWrapper>
  );
};

export default Popup;
