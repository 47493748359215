/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import diffOperations from 'utils/diffOperations';
import Wrapper from './ToolTipWrapper';

const ToolTip = ({ item, toolTipRef, handleClose, setCopiedElement }) => {
  const [value, setValue] = useState(item.text || '');
  const inputRef = useRef(null);

  const [copySuccess, setCopySuccess] = useState(false);
  const [place, setPlace] = useState(null);

  const docWidth = document.documentElement.clientWidth;
  const docHeight = document.documentElement.clientHeight;
  const DOMRect =
    toolTipRef && toolTipRef.current ? toolTipRef.current.getBoundingClientRect() : null;

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });

    return ref.current;
  }

  const prevPlace = usePrevious(place);

  useEffect(() => {
    if (place === prevPlace) {
      return () => false;
    }

    handleTooltipPosition();
    setValue(item.text);

    return () => {
      setPlace(null);

      return false;
    };
  }, [item, place]);

  const handleTooltipPosition = () => {
    if (DOMRect && DOMRect.bottom > docHeight && DOMRect.right > docWidth) {
      setPlace('bottom-right');
    } else if (DOMRect && DOMRect.bottom > docHeight) {
      setPlace('bottom');
    } else if (DOMRect && DOMRect.right > docWidth) {
      setPlace('right');
    } else {
      setPlace('left');
    }
  };

  const copyCodeToClipboard = () => {
    inputRef.current.select();
    document.execCommand('copy');

    const operations = diffOperations(item.text, value);

    setCopiedElement({
      top: item.top,
      left: item.left,
      page: item.page,
      operations,
      baseString: item.text,
    });
    setCopySuccess(true);
  };

  return (
    <Wrapper {...item} ref={toolTipRef} place={place} id="tooltip">
      <div className="tooltip-close">
        <i className="icon-close" onClick={handleClose} />
      </div>
      <div className="tooltip-input">
        <textarea
          type="text"
          name="tooltip-input"
          value={value}
          ref={inputRef}
          onChange={e => setValue(e.target.value)}
        />
        <button type="button" className="submit-form" onClick={copyCodeToClipboard}>
          Copier
          {copySuccess && <i className="icon-check-ok" />}
        </button>
      </div>
    </Wrapper>
  );
};

ToolTip.propTypes = {
  item: PropTypes.object,
  handleClose: PropTypes.func,
  setCopiedElement: PropTypes.func,
};

export default ToolTip;
