import * as Yup from 'yup';

// const phoneRegExp = /^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$/;
const decimalRegExp = /^\d*\.{1}\d*$/;
const oldLicensePlate = /^[0-9]{1,4}[A-HJ-NP-TV-Z]{1,4}[0-9]{1,2}$/;
const newLicensePlate = /^[A-HJ-NP-TV-Z]{1,2}[0-9]{1,3}[A-HJ-NP-TV-Z]{1,2}$/;

const validationSchema = () => {
  return Yup.object({
    licensePlate: Yup.string()
      .test('licensePlate', "L'immatriculation n'est pas valide", value => {
        if (value) {
          return (
            Yup.string()
              .matches(oldLicensePlate)
              .isValidSync(value) ||
            Yup.string()
              .matches(newLicensePlate)
              .isValidSync(value)
          );
        }

        return true;
      })
      .required('Le champ immatriculation est obligatoire'),
    totalTTC: Yup.string()
      .required('Le champ Montant T.T.C est obligatoire')
      .matches(decimalRegExp, "Total TTC n'est pas un décimal"),
  });
};

export default validationSchema;
