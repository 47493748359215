const reasonsRejection = [
  {
    name: 'illegibleDocument',
    label: 'Document illisible',
    checked: false,
  },
  {
    name: 'badBilling',
    label: 'Mauvaise entité de facturation',
    checked: false,
  },
  {
    name: 'missingInformation',
    label: 'Information(s) manquante(s)',
    checked: false,
    missingInformation: [{}],
  },
];
const missingInformation = [
  {
    name: 'nameadress',
    label: 'Nom /Adresse du Garage',
    checked: false,
  },
  {
    name: 'customer',
    label: 'Client / Entité de facturation',
    checked: false,
  },
  {
    name: 'brand',
    label: 'Marque du véhicule',
    checked: false,
  },
  {
    name: 'amount',
    label: 'Montant HT / TVA /TTC',
    checked: false,
  },
  {
    name: 'accord',
    label: 'Bon pour accord',
    checked: false,
  },
];

export { reasonsRejection, missingInformation };
