import * as Yup from 'yup';

const decimalRegExp = /^\d*\.{1}\d*$/;
const CPRegExp = /^(([1-9])|([0-8][0-9])|(9[0-5])|(2[ab]))[0-9]{3}$/;
const frenshAlphaRegex = /^[a-zA-ZÀ-ÿ]([-' ]?[a-zA-ZÀ-ÿ])*$/;
const oldLicensePlate = /^[0-9]{1,4}[A-HJ-NP-TV-Z]{1,4}[0-9]{1,2}$/;
const newLicensePlate = /^[A-HJ-NP-TV-Z]{1,2}[0-9]{1,3}[A-HJ-NP-TV-Z]{1,2}$/;

const validationSchema = (isPreValidate, isValidationStep) => {
  return Yup.object({
    garageName: Yup.string()
      .ensure()
      .required('Le Nom du Garage est obligatoire'),
    garageAddress: Yup.string().ensure(),
    garagePostalCode: Yup.string()
      .ensure()
      .required('Le code postal du garage est obligatoire')
      .matches(CPRegExp, "Le Code Postal n'est pas valide"),
    garageCity: Yup.string()
      .ensure()
      .required('Le champ ville est obligatoire')
      .matches(frenshAlphaRegex, "Le champ ville n'est pas valide"),
    accord: Yup.string()
      .ensure()
      .required('Le champ Bon pour accord est obligatoire'),
    clientName: Yup.string().required('Le nom du client est obligatoire'),
    clientAddress: Yup.string(),
    clientPostalCode: Yup.string().matches(CPRegExp, "Le Code Postal n'est pas valide"),
    clientCity: Yup.string().matches(frenshAlphaRegex, "Le champ ville n'est pas valide"),
    invoiceNumber: Yup.string().required('Le champ N°Devis/Facture est obligatoire'),
    licensePlate: Yup.string()
      .ensure()
      .test('licensePlate', "L'immatriculation n'est pas valide", value => {
        if (value) {
          return (
            Yup.string()
              .matches(oldLicensePlate)
              .isValidSync(value) ||
            Yup.string()
              .matches(newLicensePlate)
              .isValidSync(value)
          );
        }

        return true;
      })
      .required('Le champ immatriculation est obligatoire'),
    brand: Yup.string().required('Le champ marque est obligatoire'),
    model: Yup.string(),
    motorization: Yup.string().ensure(),
    kilometre: Yup.number().typeError('le Kilométrage doit être un nombre'),
    totalHT: Yup.string()
      .ensure()
      .required('Le champ total HT est obligatoire')
      .matches(decimalRegExp, "Total HT n'est pas un décimal"),
    totalTVA: Yup.string()
      .ensure()
      .required('Le champ total tva est obligatoire')
      .matches(decimalRegExp, "Total TVA n'est pas un décimal"),
    totalTTC: Yup.string()
      .ensure()
      .required('Le champ total ttc est obligatoire')
      .matches(decimalRegExp, "Total TTC n'est pas un décimal"),
    ...(!isValidationStep && {
      date: Yup.date()
        .transform((_, originalvalue) => {
          return new Date(originalvalue.replace(/(\d{2})\/(\d{2})\/(\d{2})/, '$2/$1/$3'));
        })
        .required('Le champ date est obligatoire')
        .typeError('le format de date doit étre jj/mm/aaaa'),
    }),
    day: Yup.number()
      .typeError("Le jour n'est pas valide")
      .when('date', (date, schema) => {
        if (date && isPreValidate) {
          schema
            .required('Le champ jour est obligatoire')
            .min(1, "Le jour n'est pas valide")
            .max(31, "Le jour n'est pas valide");
        }

        return schema.notRequired();
      }),
    month: Yup.number()
      .typeError("Le mois n'est pas valide")
      .when('date', (date, schema) => {
        if (date && isPreValidate) {
          schema
            .required('Le champ année est obligatoire')
            .min(1, "Le mois n'est pas valide")
            .max(12, "Le mois n'est pas valide");
        }

        return schema.notRequired();
      }),
    year: Yup.number()
      .typeError("L'année n'est pas valide")
      .when('date', (date, schema) => {
        return date && isPreValidate
          ? schema.required('Le champ année est obligatoire')
          : schema.notRequired();
      }),
  });
};

export default validationSchema;
