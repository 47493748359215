import styled from 'styled-components';
import { colors } from '../../static/theme';

const Wrapper = styled.div`
  .rejected-popup {
    background: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    .rejected-popup-message {
      position: fixed;
      top: 30%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: ${colors.white};
      padding: 20px;
      border-radius: 5px;
      box-shadow: 0px 0px 18px -1px rgba(0, 0, 0, 0.75);
      font-size: 25px;
      text-align: center;
      line-height: 40px;
      color: ${colors.SunsetOrange};
    }
  }
`;

export default Wrapper;
