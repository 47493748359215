import styled from 'styled-components';
import { colors, customScroll } from '../../static/theme';

const Wrapper = styled.div`
  display: flex;
  height: calc(100vh - 115px);
  .counter {
    width: 100px;
    height: 30px;
    background: #a0e336;
    border-radius: 20px;
    font-size: 13px;
    padding: 8px;
    text-align: center;
    line-height: 15px;
    position: fixed;
    top: 20px;
    right: 20px;
  }

  .model_infos {
    display: none;
    background: white;
    width: 50%;
    position: fixed;
    z-index: 1;
    min-height: 150px;
    border: 1px solid gray;
    top: 70px;
    right: 150px;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 2px 3px 5px gray;
    overflow: auto;

    p {
      margin-bottom: 10px;
    }
  }

  .model_infos-icon {
    width: 60px;
    height: 15px;
    background: orange;
    border-radius: 20px;
    font-size: 13px;
    padding: 8px;
    text-align: center;
    line-height: 15px;
    position: fixed;
    top: 30px;
    right: 150px;
    color: white;
    cursor: pointer;

    :hover + .model_infos {
      display: block;
    }
  }

  .left-content {
    flex: 1;
    overflow: auto;
    background-color: ${colors.concrete};
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
    padding: 30px;
    ${customScroll}
    h1 {
      font-size: 18px;
      font-weight: bold;
      line-height: 22px;
      margin-bottom: 20px;
    }
    p {
      font-family: Montserrat;
      font-size: 15px;
      line-height: 19px;
      margin-bottom: 30px;
    }
    .form {
      .block-title {
        margin-bottom: 20px;
      }
      .form-groupe {
        margin-bottom: 30px;
        &.error {
          input {
            border-color: red;
            border: 1px solid red;
          }
        }
        .input-content {
          position: relative;
          i {
            cursor: pointer;
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            font-size: 25px;
          }
        }
        input {
          display: block;
          box-sizing: border-box;
          height: 45px;
          width: 100%;
          border: 1px solid ${colors.gray};
          border-radius: 2px;
          margin-top: 13px;
          padding-left: 10px;
        }
        label {
          font-size: 14px;
          font-weight: 600;
          line-height: 18px;
          margin: 10px;
        }
        .form-error {
          color: red;
          margin-left: 10px;
          margin-top: 5px;
        }
      }
      .form-radio-box {
        margin: 10px 0;
        display: flex;
        align-items: center;
        .radio-box-container {
          position: relative;
          padding-left: 25px;
          margin: 0 20px 12px 0;
          cursor: pointer;
          font-size: 22px;
          user-select: none;
          label {
            font-size: 18px;
            cursor: pointer;
          }
          input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
          }
          /* Create a custom radio button */
          .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 20px;
            width: 20px;
            background-color: #999;
            border: 1px solid #ccc;
            border-radius: 50%;
          }

          /* On mouse-over, add a grey background color */
          &:hover input ~ .checkmark {
            background-color: #ccc;
            border: 1px solid #999;
          }

          /* When the radio button is checked, add a blue background */
          input:checked ~ .checkmark {
            background-color: #1e3c81;
          }
          /* Create the indicator (the dot/circle - hidden when not checked) */
          .checkmark:after {
            content: '';
            position: absolute;
            display: none;
          }

          /* Show the indicator (dot/circle) when checked */
          input:checked ~ .checkmark:after {
            display: block;
          }

          /* Style the indicator (dot/circle) */
          .checkmark:after {
            top: 6px;
            left: 6px;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: white;
          }
        }
      }
    }
    .form-action {
      .show-table {
        margin-bottom: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        button {
          height: 37px;
          width: 194px;
          border: 1px solid ${colors.bayOfMany};
          border-radius: 2px;
          background-color: ${colors.white};
          color: ${colors.bayOfMany};
          font-family: Montserrat;
          font-size: 16px;
          font-weight: 600;
          cursor: pointer;
        }
        i {
          border-radius: 50%;
          color: green;
          border: 1px solid green;
          width: 32px;
          height: 32px;
          font-size: 16px;
          line-height: 32px;
          text-align: center;
          cursor: pointer;
        }
      }
      .include_section {
        margin: 20px 0;
        .container {
          display: block;
          position: relative;
          padding-left: 35px;
          margin-bottom: 12px;
          cursor: pointer;
          font-size: 16px;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
          }

          /* Create a custom checkbox */
          .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 25px;
            width: 25px;
            background-color: #eee;
          }
          &:hover input ~ .checkmark {
            background-color: #ccc;
          }
          /* When the checkbox is checked, add a blue background */
          input:checked ~ .checkmark {
            background-color: #1e3c81;
          }
          /* Create the checkmark/indicator (hidden when not checked) */
          .checkmark:after {
            content: '';
            position: absolute;
            display: none;
          }

          /* Show the checkmark when checked */
          input:checked ~ .checkmark:after {
            display: block;
          }

          /* Style the checkmark/indicator */
          .checkmark:after {
            left: 9px;
            top: 5px;
            width: 5px;
            height: 10px;
            border: solid white;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
          }
        }
      }
      button {
        height: 36px;
        width: 260px;
        border-radius: 2px;
        cursor: pointer;
        font-weight: 600;
        &.submit-form {
          background-color: ${colors.bayOfMany};
          border: 0;
          color: ${colors.white};
          &[disabled] {
            cursor: not-allowed;
            color: ${colors.white};
            background-color: rgba(0, 0, 0, 0.2);
            border-color: ${colors.concrete};
          }
        }
        &.trust-model {
          margin-top: 15px;
          border: 2px solid ${colors.bayOfMany};
          color: ${colors.bayOfMany};
          background: ${colors.white};
          &[disabled] {
            cursor: not-allowed;
            color: ${colors.grey};
            border: 2px solid ${colors.grey};
          }
        }
        &.reject-model {
          margin-top: 15px;
          color: ${colors.SunsetOrange};
          border-color: ${colors.SunsetOrange};
        }
      }
    }
    .ocr-erro-msg {
      margin-top: 10px;
      line-height: 1.5;
      color: red;
    }
  }
  .prevalidation__number {
    margin: 15px auto !important;
  }
`;

export default Wrapper;
