import styled from 'styled-components';

export default Component => styled(Component)`
  position: absolute;
  bottom: 0;
  width: 100%;
  .progress-bar {
    width: 100%;
    .progress-bar-fill {
      width: ${({ percent }) => `${percent}%`};
      height: 12px;
      background: green;
      transition: width 0.5s ease-in-out;
    }
  }
`;
