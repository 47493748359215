import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Header from 'components/Header';
import Validation from 'pages/Validation';
import Confirmation from 'pages/Confirmation';
import ClientValidation from 'pages/ClientValidation';
import ModelsTest from 'pages/ModelsTest';
import AllfleetStat from 'pages/AllfleetStat';
import GarageValidation from 'pages/GarageValidation';
import CGV from 'pages/ClientValidation/CGV';
import RGPD from 'pages/ClientValidation/RGPD';
import { PopupProvider } from 'context/PopupContext';
import { ModalProvider } from 'context/ModalContext';

import './static/reset.css';
import './static/iconStyle.css';

const App = () => (
  <>
    <BrowserRouter>
      <Header />
      <ModalProvider>
        <PopupProvider>
          <Switch>
            <Route path="/edition/:token" exact component={Validation} />
            <Route path="/quotation/validate/:token" exact component={Validation} />
            <Route path="/quotation/accept-or-deny/:refId" exact component={ClientValidation} />
            <Route path="/checauto/garage-validation/:refId" exact component={GarageValidation} />
            <Route path="/confirmation" exact component={Confirmation} />
            <Route path="/test" exact component={ModelsTest} />
            <Route path="/allfleetStat" exact component={AllfleetStat} />
            <Route path="/:brand/cgv" exact component={CGV} />
            <Route path="/:brand/rgpd" exact component={RGPD} />
          </Switch>
        </PopupProvider>
      </ModalProvider>
    </BrowserRouter>
  </>
);

export default App;
