import styled from 'styled-components';

const PopupWrapper = styled.div`
  .behind-popup {
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1000;
  }
  .pop_up_content {
    width: 480px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    background: #fff;
    padding: 30px;
    border-radius: 16px;
    box-shadow: 0px 0px 18px -1px rgba(0, 0, 0, 0.75);
    .popup-body__text-block .title {
      font-weight: bold;
      font-size: 15px;
      color: #000000;
      display: flex;
      margin-bottom: 12px;
    }
    .popup-body__text-block .title i {
      font-style: italic;
      font-weight: 100;
      color: #1e3c81;
      display: flex;
      margin-left: 10px;
    }
    .popup-body__text-block .reasons {
      margin-bottom: 25px;
    }
    .popup-body__text-block .reasons label {
      display: flex;
      padding: 6px;
      margin-bottom: 7px;
      align-items: center;
      input {
        margin-right: 15px;
      }
    }
    .popup-body__text-block .missingInformation {
      margin-left: 25px;
    }
    .popup-body__text-block textarea {
      background: #ffffff;
      border: 1px solid #1e3c81;
      box-sizing: border-box;
      border-radius: 2px;
      width: 100%;
      padding: 20px;
      margin-bottom: 15px;
    }
    .popup-body__text-block .popup-button-rejet {
      font-weight: bold;
      color: #ffffff;
      background: #1e3c81;
      border-radius: 2px;
      text-align: center;
      width: 100%;
      height: 40px;
      cursor: pointer;
      border: none;
    }
    .popup-body__text-block .popup-button-rejet[disabled] {
      border: 1px solid #707685;
      background-color: #707685;
      color: #fff;
      cursor: not-allowed;
    }
    .popup-body__text-block .errors {
      font-style: italic;
      font-weight: 300;
      color: #dc143c;
      display: none;
    }
  }
`;
export default PopupWrapper;
