import styled from 'styled-components';

const InputWrapper = styled.div`
  display: ${({ isInline }) => (isInline ? 'inline-block' : undefined)};
  margin: 0;
  margin-right: ${({ isInline }) => (isInline ? '20px' : undefined)};
  padding: 0;
  position: relative;

  .bold {
    font-weight: bold;
  }

  .wrapper {
    display: flex;

    span {
      font-size: 14px;
      line-height: 18px;
      display: inline-block;
      padding-bottom: 10px;
      height: 19px;
      color: ${({ color }) => color && color};
    }

    .label {
      padding-bottom: 0;
      top: ${({ isEditable }) => (isEditable ? '5px' : undefined)};
      position: relative;
      margin-right: 5px;
      white-space: nowrap;
    }

    input,
    textarea {
      border: none;
      margin: 5px 0;
      width: 100%;
      transition: 0.2s ease;

      outline: none;
      background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        color-stop(96%, rgba(255, 255, 255, 0)),
        color-stop(4%, gray)
      );

      background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 96%, gray 4%);
      background-position: -400px 0;
      background-size: 400px 100%;
      background-repeat: no-repeat;
      border-bottom: 1px solid #ddd;
      &:focus {
        background-position: 0 0;
      }
    }
  }
`;

export default InputWrapper;
