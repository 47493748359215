import styled from 'styled-components';
import { colors, customScroll } from 'static/theme';

const Wrapper = styled.div`
  flex: 4;
  position: relative;
  font-size: 16px;
  display: block;
  overflow-y: auto; /* Trigger vertical scroll    */
  overflow-x: hidden; /* Hide the horizontal scroll */
  ${customScroll}
  .pagination {
    position:sticky;
    padding: 5px;
    margin-left: 2px;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    z-index: 1000;
    box-sizing: border-box;
    background: rgba(0, 0, 0, 0.3);
    width: 100px;
    button {
      border: 0;
      background: transparent;
      cursor: pointer;
      &[disabled] {
        cursor: not-allowed;
        color: ${colors.grey};
      }
    }
    .page-info {
      margin: 0 5px;
    }
  }
  img {
    width: 100%;
    height: auto;
    position: absolute;
    top: 0px;
    left: 0;
  }
  .ocr {
    position: absolute;
    top: 0;
    left: 0;
    width: ${({ imgWidth }) => `${imgWidth}px`}
    height: ${({ imgHeight }) => `${imgHeight}px`}
  }
  .loader {
    padding: 0;
    height: auto;
  }
`;

export default Wrapper;
