import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import config from 'config';
import Loader from 'components/Loader';
import Validation from './Validation';
import PreValidation from './PreValidation';

export default () => {
  const { token } = useParams();

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const result = await fetch(`${config.managerUrl}/documents/${token}`);
      const dataToJson = await result.json();
      setData(dataToJson);
      setLoading(false);
    };

    fetchData();
  }, [token]);

  if (loading) {
    return <Loader />;
  }

  if (!loading && data && data.error && data.tokenError) {
    return <p style={{ margin: '30px', textAlign: 'center' }}>{data.message}</p>;
  }

  return data.isPreValidate ? (
    <Validation data={data} token={token} />
  ) : (
    <PreValidation data={data} token={token} />
  );
};
