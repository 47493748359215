import config from 'config';

export function DownloadPdf(refId) {
  const link = document.createElement('a');
  link.href = `${config.managerUrl}/documents/downloadPdf/${refId}`;
  // link.setAttribute('download','DX500YL');
  // link.setAttribute('target', '_blank');
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function DownloadMedias(refId) {
  const link = document.createElement('a');
  link.href = `${config.managerUrl}/mediaCheck/getArchivedMedia/${refId}`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
