import React from 'react';
import LoaderWrapper from './MoreWrapper';

const Loader = () => (
  <LoaderWrapper>
    <svg className="svg-circleplus" viewBox="0 0 100 100">
      <circle cx="50" cy="50" r="45" fill="none" strokeWidth="7.5" />
      <line x1="32.5" y1="50" x2="67.5" y2="50" strokeWidth="5" />
      <line x1="50" y1="32.5" x2="50" y2="67.5" strokeWidth="5" />
    </svg>
  </LoaderWrapper>
);

export default Loader;
