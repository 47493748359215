import styled from 'styled-components';
import { colors, customScroll } from 'static/theme';

const Wrapper = styled.div`
  ${({ showTable }) => !showTable && 'display: none'}
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${colors.concrete};
  z-index: 1000;
  table {
    width: 100vw;
    thead,
    tbody tr {
      display: table;
      width: 100%;
      table-layout: fixed;
    }
    td:first-child,
    th:first-child {
      padding-left: 30px;
    }
    thead {
      background-color: ${colors.alto};
      width: 100%;
      tr {
        th {
          padding: 17px 15px 17px 0;
          color: ${colors.codGray};
          font-size: 14px;
          font-weight: 600;
          text-align: left;
          width: 9%;
          &.type {
            width: 13%;
          }
          &.reference {
            width: 14%;
          }
          &.designation {
            width: 24%;
          }
          &.action {
            width: 3%;
            text-align: center;
            i {
              color: red;
              cursor: pointer;
              /* font-size: 20px; */
            }
          }
        }
      }
    }
    tbody {
      width: 100vw;
      display: block;
      max-height: 150px;
      overflow-y: auto; /* Trigger vertical scroll    */
      overflow-x: hidden; /* Hide the horizontal scroll */
      ${customScroll}
      tr {
        width: 100%;
        transition: width 2s;
      }
      td {
        padding: 17px 15px 17px 0;
        width: 9%;
        input {
          height: 45px;
          border: 1px solid ${colors.gray};
          border-radius: 2px;
          width: 100%;
          box-sizing: border-box;
        }
        &.type {
          width: 13%;
        }
        &.ref {
          width: 14%;
        }
        &.designation {
          width: 24%;
        }
        &.action {
          text-align: center;
          width: 3%;
          i {
            color: red;
            cursor: pointer;
          }
        }
      }
    }
  }
  .table-action {
    margin-left: 30px;
    margin-bottom: 27px;
    .add-line {
      height: 36px;
      width: 188px;
      border: 1px solid ${colors.bayOfMany};
      border-radius: 2px;
      background-color: ${colors.white};
      color: ${colors.bayOfMany};
      font-size: 16px;
      font-weight: 600;
      cursor: pointer;
    }
  }
`;

export default Wrapper;
