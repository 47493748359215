import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import Loader from 'components/Loader';
import RejectedDocument from 'components/RejectedDocument';
import Wrapper from './PrevalidationWrapper';
import Preview from './components/Preview';
import History from './components/History';
import FormPreValidation from './components/FormPreValidation';

const PreValidation = ({ data, token }) => {
  const location = useLocation();
  console.log(data)

  const [copiedElement, setCopiedElement] = useState(null);
  const [modelInfos, setModelInfos] = useState({});
  const [isLoadingEdit, setLoadingEdit] = useState(false);

  if (!location.state) {
    return (
      <History
        current={data?.imagesUrls[0]?.url}
        date={data?.creationDate}
        divergence={data?.lastDivergence}
      />
    );
  }

  const apiData = { ...data };

  if (location.state === 'reset' || location.state === 'new') {
    const { data: documentData, initModel } = apiData;
    apiData.counter = 0;
    const parsedInitModel = JSON.parse(initModel);
    const { values } = parsedInitModel;
    apiData.data = { ...documentData, ...values };
  }
     
  const { counter } = apiData;

  return !isLoadingEdit ? (
    <Wrapper>
      {!data?.isRejected ? (
        <>
         <div
            className="counter"
            style={{
              background: counter + 1 > 1 ? '#a0e336' : '#36a3e3',
              color: counter + 1 > 1 ? 'initial' : 'white',
            }}
          >
            {counter + 1 > 1 ? `Modèle connu: ${counter + 1} documents` : 'Nouveau modèle'}
          </div>
          <div className="model_infos-icon">Infos</div>
          <div className="model_infos">
            {apiData.automationErrors.map(err => (
              <p>- {err}</p>
            ))}
          </div>
          <FormPreValidation
            data={apiData}
            copiedElement={copiedElement}
            setModelInfos={setModelInfos}
            modelInfos={modelInfos}
            modelId={data.modelId}
            token={token}
            setLoadingEdit={setLoadingEdit}
            action={location.state}
          />
        </>
      ) : (
        <RejectedDocument />
      )}
      <Preview data={data} setCopiedElement={setCopiedElement} disabled={data?.isRejected} />
    </Wrapper>
  ) : (
    <Loader />
  );
};

PreValidation.propTypes = {
  data: PropTypes.object.isRequired,
  token: PropTypes.string,
};

export default PreValidation;
