import styled from 'styled-components';

export default Component => styled(Component)`
  h3 {
    display: block;
    margin: 16px 0;
    font-weight: 600;
  }
  .dropzone {
    position: relative;
    width: 298px;
    height: 199px;
    border: 1px solid #c3d0e9;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    .dropzone_button {
      width: 104px;
      height: 29px;
      background-color: #1e3c81;
      color: #ffffff;
      border: none;
      cursor: pointer;
    }
    .file_droped {
      display: flex;
      width: 80%;
      justify-content: space-around;
      .file_name {
        color: #000000;
        magrin: 10px;
      }
      img {
        cursor: pointer;
      }
    }
    p {
      text-align: center;
      line-height: 25px;
    }
    span {
      color: red;
    }
  }
  em {
    font-size: 10px;
  }
`;
