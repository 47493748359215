import React from 'react';
import PropTypes from 'prop-types';
import InputWrapper from './InputWrapper';

const EditableInput = props => {
  const {
    className,
    value,
    isEditable,
    isBoldLabel,
    isBoldValue,
    isDesignation,
    label,
    isInline,
    color,
    isTextArea,
    name,
    handleChange,
    placeholder,
    setFieldValue
  } = props;

  return (
    <InputWrapper
      className={`${className} editable_input`}
      isInline={isInline}
      isEditable={isEditable}
      color={color}
    >
      <div className="wrapper">
        {label && <span className={isBoldLabel ? 'label bold' : 'label'}>{label}</span>}
        {isEditable ? ( //eslint-disable-line
          !isTextArea ? (
            <input
              type="text"
              placeholder={placeholder}
              onChange={handleChange}
              onBlur={(e) => setFieldValue(name, e.target.value.trim())}
              value={value || ''}
              name={name}
            />
          ) : (
            <textarea value={value} onChange={handleChange} name={name} />
          )
        ) : (
          <span className={isBoldValue ? 'bold' : undefined}>
            {
              value ? 
              isDesignation && value.length > 60 ? `${String(value).slice(0, 60)}  ...` : value 
              : ''
            }
          </span>
        )}
      </div>
    </InputWrapper>
  );
};

EditableInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  color: PropTypes.string,
  name: PropTypes.string,
  isEditable: PropTypes.bool,
  isBoldLabel: PropTypes.bool,
  isBoldValue: PropTypes.bool,
  isInline: PropTypes.bool,
  isTextArea: PropTypes.bool,
  handleChange: PropTypes.func,
  setFieldValue: PropTypes.func,
};

export default EditableInput;
