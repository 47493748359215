import React, { useState } from 'react';
import { map, range } from 'lodash';
import PropTypes from 'prop-types';
import TableWrapper from './TableWrapper';
import EditableInput from '../EditableInput';
import Svg from '../More';

const EditableTable = ({
  columns,
  cells,
  className,
  editableLines,
  arrayName,
  values,
  arrayHelpers,
  handleChange,
  setFieldValue,
}) => {
  const [nbRows, setNbRows] = useState(values.details.length || 1);
  const [editableRow, setditableRow] = useState(null);
  const addRow = () => {
    arrayHelpers.push({});
    setNbRows(nbRows + 1);
  };

  const activateEditionInRow = e => {
    e.preventDefault();
    setditableRow(parseInt(e.currentTarget.value, 10));
  };

  return (
    <TableWrapper className={className}>
      <thead>
        <tr>
          {map(columns, (col, index) => (
            <th key={index} align="center">
              {col}
            </th>
          ))}
          {editableLines && (
            <th align="center" className="custom_tab">
              <button className="btn_new" type="button" onClick={addRow}>
                <Svg />
              </button>
            </th>
          )}
        </tr>
      </thead>
      <tbody>
        {map(range(nbRows), row => (
          <tr key={row}>
            {values[arrayName] &&
              values[arrayName].length > 0 &&
              map(cells, (cell, index) => (
                <td
                  key={index}
                  align="center"
                  className={row === editableRow ? 'editable_cell' : undefined}
                >
                  <EditableInput
                    value={values[arrayName][row][cell.name]}
                    name={`${arrayName}.${row}.${cell.name}`}
                    isTextArea={cell.type === 'textarea'}
                    isEditable={row === editableRow}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    isDesignation={cell.name === 'designation'}
                  />
                </td>
              ))}
            <td align="center" className={row === editableRow ? 'custom_tab edit' : 'custom_tab'}>
              <button value={row} type="button" className="btn_edit" onClick={activateEditionInRow}>
                <img src="/assets/images/edit.svg" alt="edit your content" />
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </TableWrapper>
  );
};

EditableTable.propTypes = {
  columns: PropTypes.array,
  cells: PropTypes.array,
  className: PropTypes.string,
  arrayName: PropTypes.string,
  editableLines: PropTypes.bool,
  values: PropTypes.object,
  arrayHelpers: PropTypes.object,
  handleChange: PropTypes.func,
};

export default EditableTable;
