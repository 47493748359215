import React, { useState, Fragment, useContext } from 'react';
import PropTypes from 'prop-types';
import { Formik, FieldArray } from 'formik';
import { useHistory } from 'react-router-dom';
import config from 'config';
import { PopupContext } from 'context/PopupContext';
import Popup from 'components/Popup';
import Input from './Input';
import Table from '../Table';
import validationSchema from '../../validationSchema';
import initialValues from '../../initialValues';

const FormPreValidation = ({
  data,
  copiedElement,
  setModelInfos,
  modelInfos,
  modelId,
  token,
  setLoadingEdit,
  action,
}) => {
  const [showTable, setShowTable] = useState(false);
  const [include, setInclude] = useState(true);
  const [OCRErrorMsg, setOCRErrorMsg] = useState("");
  const { setComponent, setOpenPopup } = useContext(PopupContext);
  const history = useHistory();
  const nbHandledDocuments = data.nbHandledDocuments;

  const apiData = { ...data };

  const openTable = () => {
    setShowTable(true);
  };

  const showPopup = () => {
    setOpenPopup(true);
    setComponent(<Popup setOpenPopup={setOpenPopup} token={token} />);
  };

  const formInputs = [
    {
      block: 'Les informations du garage:',
      inputs: [
        { name: 'garageName', label: 'Nom' },
        { name: 'garageAddress', label: 'Adresse' },
        { name: 'garagePostalCode', label: 'CP' },
        { name: 'garageCity', label: 'Ville' },
      ],
    },
    {
      block: 'Les informations du client:',
      inputs: [
        { name: 'clientName', label: 'Nom' },
        { name: 'clientAddress', label: 'Adresse' },
        { name: 'clientPostalCode', label: 'CP' },
        { name: 'clientCity', label: 'Ville' },
      ],
    },
    {
      block: 'Autres informations:',
      inputs: [
        {
          name: 'isBill',
          type: 'radio',
          options: [
            {
              key: 'Facture',
              value: true,
            },
            {
              key: 'Devis',
              value: false,
            },
          ],
        },
        { name: 'invoiceNumber', label: 'N° Devis / Facture' },
        { name: 'accord', label: 'Bon pour accord' },
        { name: 'licensePlate', label: 'Immatriculation' },
        { name: 'brand', label: 'Marque' },
        { name: 'model', label: 'Modèle' },
        { name: 'kilometre', label: 'Kilométrage' },
        { name: 'totalHT', label: 'Tot HT' },
        { name: 'totalTVA', label: 'Tot TVA' },
        { name: 'totalTTC', label: 'Tot TTC' },
        { name: 'date', label: 'Date' },
      ],
    },
  ];

  const criticalField = [
    'totalHT',
    'totalTVA',
    'totalTTC',
    'licensePlate',
    'invoiceNumber',
    'date',
    'garageName',
    'clientName',
    'garageAddress',
    'clientAddress',
    'garagePostalCode',
    'clientPostalCode',
  ];

  if (apiData.isBill) {
    criticalField.push('accord');
  }

  const handleSubmit = async (values, actions) => {

    //Check if this docmument is already Pré-validate
    const result = await fetch(`${config.managerUrl}/documents/${token}`);
    const data = await result.json();
    if(data.isPreValidate) {
      setOCRErrorMsg(
        `Ce document a déjà été pré-valider le 
        ${new Date(data.isPreValidate._seconds * 1000).toLocaleString('fr-FR').split(',').join(' à ')}`);
      return;
    }

    const { setFieldValue, setErrors } = actions;

    const totErrorMsg = 'La somme du total ht + le total tva est différente du total ttc';
    const nullError = 'La valeur ne doit pas être Null';
    
    const isTotalsNotKo = Math.round((Number(values.totalHT) + Number(values.totalTVA) + Number.EPSILON) * 100) / 100 !== Number(values.totalTTC)
  
    if (isTotalsNotKo || Number(values.totalTVA) === 0 || Number(values.totalTTC) === 0 || Number(values.totalHT) === 0) {
      console.log('error')
      setErrors({
        totalTVA: isTotalsNotKo ? totErrorMsg : nullError,
        totalHT: isTotalsNotKo ? totErrorMsg : nullError,
        totalTTC: isTotalsNotKo ? totErrorMsg : nullError,
      });
      return;
    }

    setLoadingEdit(true);
    const [day, month, year] = values.date.split('/');
    let fullYear = year;
    const currentSiècle = new Date()
      .getFullYear()
      .toString()
      .slice(0, 2);
    if (year?.toString().length === 2) {
      fullYear = parseInt(`${currentSiècle}${year}`, 10);
      const fullDate = new Date(fullYear, month - 1, day);
      setFieldValue('date', fullDate.toLocaleDateString('fr-FR'), true);
    }

    let criticalFieldKo = [];

    criticalFieldKo = criticalField.filter(k => {
      if (!apiData.data[k]) return true;
      if (k === 'accord' && values[k] === '99') return false
      const dataValue = apiData.data[k].toUpperCase();
      const formValue = values[k].toUpperCase();
      return !(dataValue.includes(formValue) || formValue.includes(dataValue));
    });

    const { isBill, ...restValues } = values;
    const editResult = await fetch(`${config.managerUrl}/documents/update/${token}`, {
      method: 'PUT',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
      body: JSON.stringify({
        ...apiData,
        criticalFieldKo,
        includeDoc: include,
        isBill,
        data: { ...apiData.data, ...restValues, day, month, year: fullYear },
      }),
    });
  
    const { error } = await editResult.json();
  
    if (!error) {
  
      setLoadingEdit(false);
      history.push('../confirmation', { isBill: apiData.isBill });
    }
    

    const changes = Object.keys(modelInfos).filter(k => {
      if (!apiData.data[k]) return true;
      const dataValue = apiData.data[k].toUpperCase();
      const formValue = values[k].toUpperCase();
      return !(dataValue.includes(formValue) || formValue.includes(dataValue));
    });

    let errorModel = null;

    if ((include && changes.length > 0) || ['new', 'reset'].includes(action)) {

      const editModel = await fetch(`${config.managerUrl}/models/update/${modelId}`, {
        method: 'PUT',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: JSON.stringify({ docId: apiData.id, action, changes, values, modelInfos  }),
      });
      const { error: err } = await editModel.json();
      errorModel = err;
    }
  };

  return (
    <Formik
      initialValues={initialValues(apiData)}
      validationSchema={() => validationSchema(apiData.isPreValidate, false)}
      onSubmit={async (values, actions) => handleSubmit(values, actions)}
    >
      {formikProps => (
        <div className="left-content">
          <div className="form">
            <form>
              {formInputs.map(({ block, inputs }) => (
                <Fragment key={block}>
                  <div className="block-title">{block}</div>
                  <>
                    {inputs.map(input => (
                      <Input
                        type={input.type}
                        key={input.name}
                        name={input.name}
                        label={input.label}
                        formik={formikProps}
                        setModelInfos={setModelInfos}
                        modelInfos={modelInfos}
                        copiedElement={copiedElement}
                        options={input.options}
                      />
                    ))}
                  </>
                </Fragment>
              ))}
              <FieldArray
                name="details"
                render={arrayHelpers => (
                  <Table
                    arrayName="details"
                    showTable={showTable}
                    setShowTable={setShowTable}
                    formik={formikProps}
                    arrayHelpers={arrayHelpers}
                  />
                )}
              />
            </form>
          </div>
          <div className="form-action">
            <div className="show-table">
              <button type="button" onClick={openTable}>
                Afficher le tableau
              </button>
              <i className="icon-check-ok" />
            </div>
            <div className="include_section">
              <label className="container">
                Inclure le document
                <input
                  type="checkbox"
                  id="include"
                  onChange={() => setInclude(!include)}
                  name="include"
                  checked={include}
                />
                <span className="checkmark"></span>
              </label>
            </div>
            <button type="button" className="submit-form" onClick={formikProps.handleSubmit} disabled={!!OCRErrorMsg}>
              Confirmer le formulaire
            </button>
            {OCRErrorMsg && <div className="ocr-erro-msg">{OCRErrorMsg}</div>}
            <p className="prevalidation__number">Modèle complété {nbHandledDocuments} fois</p>
            <button type="button" className="reject-model" onClick={() => showPopup()}>
              Rejeter le document
            </button>
          </div>
        </div>
      )}
    </Formik>
  );
};

FormPreValidation.propTypes = {
  action: PropTypes.string,
  setModelInfos: PropTypes.func,
  setLoadingEdit: PropTypes.func,
  data: PropTypes.object,
  copiedElement: PropTypes.object,
  modelInfos: PropTypes.object,
  modelId: PropTypes.string,
  token: PropTypes.string,
};

export default FormPreValidation;