import styled from 'styled-components';

const Wrapper = styled.div`
  .doughnuts {
    position: relative;
    margin: auto;
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
    .graph {
      width: 35vw;
    }
  }
  .legend {
    text-align: center;
    padding-bottom: 10px;
  }

  .bar {
    max-width: 1200px;
    margin: auto;
  }

  .model-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 100%;

    img {
      width: 250px;
    }
  }
`;

export default Wrapper;
