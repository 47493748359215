import React, { useState } from 'react';

export const PopupContext = React.createContext();

export const PopupProvider = ({ children }) => {
  const [Component, setComponent] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);

  return (
    <PopupContext.Provider value={{setComponent, setOpenPopup}}>
      {openPopup && Component}
      {children}
    </PopupContext.Provider>
  );
};