import styled from 'styled-components';
import { customScroll, colors } from 'static/theme';

const tooltipWidth = '186px';
const tooltipHeight = '175px';
const Wrapper = styled.div`
  position: absolute;
  font-size: 16px;
  top: ${({ top }) => `${top * 100}%`};
  left: ${({ left }) => `${left * 100}%`};
  margin-top: ${({ height }) => `calc(${height * 100}% + 10px)`};
  margin-top: ${({ place, height }) =>
    (place === 'bottom' || place === 'bottom-right') &&
    `calc((${tooltipHeight} + ${height * 100}% + 20px) * -1)`};
  cursor: auto;
  margin-left: ${({ place, width }) =>
    (place === 'right' || place === 'bottom-right') &&
    `calc((${tooltipHeight} - ${width * 100}% + 10px) * -1)`};
  height: ${tooltipHeight};
  width: ${tooltipWidth};
  border-radius: 4px;
  background-color: ${colors.white};
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  padding: 20px;
  z-index: 999;
  .tooltip-close {
    position: absolute;
    top: 13px;
    right: 13px;
    cursor: pointer;
    i {
      color: red;
    }
  }
  .tooltip-input {
    margin-top: 56px;
    display: flex;
    flex-direction: column;
    align-items: center;
    textarea {
      box-sizing: border-box;
      height: 44px;
      width: 180px;
      resize: none;
      overflow-y: scroll;
      ${customScroll}
      border: 1px solid #1e3c81;
      border-radius: 4px;
    }
    button {
      margin-top: 30px;
      height: 36px;
      width: 128px;
      border-radius: 2px;
      background-color: #1e3c81;
      border: 0;
      color: ${colors.white};
      cursor: pointer;
      &[disabled] {
        cursor: not-allowed;
        color: ${colors.white};
        background-color: rgba(0,0,0,0.2);
        border-color: ${colors.concrete};
      }
      i {
        margin-left: 5px;
        color: green;
      }
    }
  }
`;

export default Wrapper;
