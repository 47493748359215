import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import config from 'config';
import Dropzone from 'components/DropZone';
import uploadStream from 'utils/uploadStream';
import firebase, { db } from 'firebase.js';
import validationSchema from './validationSchema';
import withGarageValidationWrapper from './GarageValidationWrapper';

function GarageValidation({ className }) {
  const [media, setMedia] = useState(null);
  const [fileInput, setFileInput] = useState(null);
  const [file, setFile] = useState(null);
  const [percent, setPercent] = useState(0);
  const [errorSubmitting, setErrorSubmitting] = useState(null);

  const history = useHistory();

  const { refId } = useParams();

  useEffect(() => {
    const fetchMedia = async () => {
      const result = await fetch(`${config.managerUrl}/mediaCheck/getMedia/${refId}`);
      const data = await result.json();
      setMedia(data);
    };

    const fetchFile = async () => {
      const result = await fetch(`${config.managerUrl}/files/getFileByRefId/${refId}`);
      const data = await result.json();
      setFile(data.data);
    };

    fetchMedia();
    fetchFile();
  }, [refId]);

  const initialValues = {
    licensePlate: file?.licensePlate || '',
    driverName: file?.driverName || '',
    garageComment: file?.garageComment || '',
    totalTTC: file?.totalTTC || '',
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: values => handleSubmit(values),
    validationSchema,
  });

  const handleSubmit = async values => {
    try {
      setErrorSubmitting(null);
      const documentUrl = await uploadStream(fileInput, setPercent);
      await db
        .collection('files')
        .doc(refId)
        .set(
          {
            ...values,
            documentInfo: {
              documentUrl,
              fileName: fileInput.name,
              creationDateFile: firebase.firestore.Timestamp.fromDate(new Date()),
            },
          },
          { merge: true },
        );
      // send Email to client
      const result = await fetch(
        `${config.managerUrl}/documents/particular/ValidationRequest/${refId}`,
        {
          method: 'POST',
        },
      );
      const { error, message } = await result.json();
      if (!error) {
        history.push('/confirmation', { isBill: false, fromPage: 'garage_validation' });
      } else {
        setErrorSubmitting(message);
      }
    } catch (err) {
      console.log('error: ', err);
      setErrorSubmitting("Une erreur s'est produite");
    }
  };

  const TextInput = ({ name, label, type = 'text', em, handleBlur, disabled = false }) => {
    const {
      touched: { [name]: touchedField },
      errors: { [name]: errorField },
      values: { [name]: value },
    } = formik;

    return (
      <div className={`${touchedField && errorField ? 'form-groupe error' : 'form-groupe'}`}>
        <label htmlFor={name}>{label}</label>
        <em>{em}</em>
        <input
          type={type}
          id={name}
          name={name}
          onChange={formik.handleChange}
          onBlur={e => (handleBlur ? handleBlur(e) : formik.handleBlur)}
          value={value}
          min="0"
          disabled={disabled}
        />
        {touchedField && errorField && <div className="form-error">{errorField}</div>}
      </div>
    );
  };

  TextInput.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.string,
    em: PropTypes.string,
    handleBlur: PropTypes.func,
    disabled: PropTypes.bool,
  };

  return (
    <div className={className}>
      <h2>Intervention Checkauto</h2>

      <div className="media-list">
        {media?.data?.urls &&
          media?.data?.urls.map((el, i) => (
            <div className="media-link" key={el.url}>
              <a href={el.url || el} target="_blank" rel="noreferrer">
                <img src="/assets/images/download_file.svg" alt="" />
                Voir le média {i + 1}
              </a>
            </div>
          ))}
      </div>

      <div className="form">
        {TextInput({ name: 'licensePlate', label: 'Immatriculation *', disabled: true })}
        {TextInput({ name: 'driverName', label: 'Nom & Prénom', disabled: true })}
      </div>
      <Dropzone fileInput={fileInput} setFileInput={setFileInput} percent={percent} />
      <div className="totalTTC">
        {TextInput({
          name: 'totalTTC',
          type: 'number',
          label: 'Montant T.T.C. du devis *',
          em: 'Le montant doit correspondre à celui du devis',
          handleBlur: e => {
            formik.setFieldValue('totalTTC', parseFloat(e.target.value).toFixed(2));
          },
        })}
      </div>
      <div className="comment">
        <div className="form-groupe">
          <label htmlFor="comment">
            Commentaire
            <em>Optionnel</em>
          </label>
          <textarea
            className="comment_input"
            id="garageComment"
            name="garageComment"
            rows="10"
            value={formik.values.garageComment}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </div>
      </div>
      <button
        className="submit_button"
        type="button"
        disabled={!(formik.isValid && fileInput) || formik.isSubmitting}
        onClick={formik.handleSubmit}
      >
        {formik.isSubmitting ? 'Envoi en cours ...' : 'Envoyer le devis au conducteur'}
      </button>
      {errorSubmitting && <span className="error-submitting">{errorSubmitting}</span>}
      {!(formik.isValid && fileInput) && (
        <span className="warning">
          Veuillez remplir l’ensemble des champs sauf ceux indiqués comme optionnel.
        </span>
      )}
    </div>
  );
}

GarageValidation.propTypes = {
  className: PropTypes.string,
};

export default withGarageValidationWrapper(GarageValidation);
