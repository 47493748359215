import React from 'react';
import PropTypes from 'prop-types';
import BlockWrapper from './BlockWrapper';

const EditableBlock = ({
  children,
  className,
  setBlock,
  block,
  editableBlock,
  handleChange,
  setFieldValue,
  customClassName,
}) => {
  const setCurrentBlock = () => setBlock(block);

  const elements = React.Children.map(children, child =>
    React.cloneElement(
      child,
      typeof child.type !== 'string' ? { isEditable: block === editableBlock, handleChange, setFieldValue } : {},
    ),
  );

  return (
    <BlockWrapper className={`${className} editable_block`}>
      <div className={customClassName}>{elements}</div>
      <div className="edition_block_button">
        <button
          type="button"
          className={block === editableBlock ? 'btn_edit edition' : 'btn_edit'}
          onClick={setCurrentBlock}
        >
          <img src="/assets/images/edit.svg" alt="edit your content" />
        </button>
      </div>
    </BlockWrapper>
  );
};

EditableBlock.propTypes = {
  className: PropTypes.string,
  block: PropTypes.string,
  editableBlock: PropTypes.string,
  setBlock: PropTypes.func,
  handleChange: PropTypes.func,
  children: PropTypes.node,
  customClassName: PropTypes.string,
};

export default EditableBlock;
