import styled from 'styled-components';
import { colors } from '../../static/theme';

const BlockWrapper = styled.div`
  display: flex;
  .edition_block_button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .btn_edit {
      margin-left: 30px;
      cursor: pointer;
    }
    .edition {
      background: ${colors.grey};
    }
  }
`;

export default BlockWrapper;
