import styled from 'styled-components';
import { colors, breakPoints } from '../../static/theme';

const TableWrapper = styled.table`
  text-align: center;
  th {
    background-color: ${colors.blue};
    color: ${colors.white};
    padding: 12px;
    max-width: 320px;
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    @media screen and (max-width: ${breakPoints.tablet.minWidth}px) {
      padding: 5px;
    }
  }
  .editable_cell {
    background: ${colors.white};
    border: 1px solid blue;
  }

  .btn_new {
    border: none;
    background: #ffffff;
    outline: 0;
    cursor: pointer;
    margin: 0;
    padding: 0;
    width: 38px;
    height: 38px;

    > div {
      width: 38px;
      height: 38px;
    }
  }

  td {
    .edition_block {
      cursor: pointer;
      width: 17px;
      height: 17px;
      img {
        width: 17px;
        height: 17px;
      }
    }
  }

  th,
  td {
    vertical-align: middle;
  }

  .custom_tab {
    background: white;
    width: 34px;
    padding: 11px 12px;
    padding-right: 0;
    .btn_edit {
      background: white;
    }
  }

  .edit .btn_edit {
    background: ${colors.grey};
  }
`;

export default TableWrapper;
