import React from 'react';
import PropTypes from 'prop-types';
import Wrapper from './TextItemWrapper';

const TextItem = ({ item, index, setTooltip, textRef }) => {
  return (
    <Wrapper item={item} ref={textRef} onClick={() => setTooltip({ item, index })}>
      <div className="text" />
    </Wrapper>
  );
};

TextItem.propTypes = {
  item: PropTypes.object,
  index: PropTypes.number,
  setTooltip: PropTypes.func,
};

export default TextItem;
