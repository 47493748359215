import styled from 'styled-components';

export default Component => styled(Component)`
  font-family: Montserrat;
  font-style: normal;
  position: relative;
  padding: 60px;
  .title {
    font-weight: bold;
    font-size: 28px;
    line-height: 34px;
    text-align: center;
    color: #1e3c81;
  }
  .models {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    span {
      font-size: 18px;
    }
    .model_content {
      width: 49%;
      .model_image {
        position: relative;
        margin-top: 30px;
        padding: 40px;
        border: 1px solid #979797;
        border-radius: 4px;
        height: 736px;
        img {
          width: 100%;
          height: 736px;
        }
        margin-bottom: 20px;
      }
      .zoom_in {
        display: none;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        justify-content: center;
        align-items: center;
        cursor: pointer;
        i {
          font-size: 25px;
          line-height: 25px;
          color: #1e3c81;
        }
        width: 50px;
        height: 50px;
        background: #edf1f7;
        opacity: 0.8;
        border: 1px solid #e6e6e6;
        border-radius: 50%;
      }
      &:hover .zoom_in {
        display: flex;
      }
      .model_info {
        font-size: 13px;
      }
      .chevrons {
        display: flex;
        button {
          border: none;
          background: none;
        }
        i {
          padding: 10px 13px;
          background: rgba(0, 64, 137, 0.1);
          margin-left: 10px;
          cursor: pointer;
        }
      }
    }
  }
  .CTA {
    padding: 40px 0;
    display: flex;
    justify-content: flex-end;
    button {
      border: 1px solid;
      border-radius: 2px;
      padding: 10px 20px;
      cursor: pointer;
      font-weight: 600;
      font-size: 14px;
      margin-left: 20px;
      &.create_model {
        border-color: #1e3c81;
        background: #ffffff;
        color: #1e3c81;
      }
      &.reset_model {
        border-color: #d0021b;
        background: #ffffff;
        color: #d0021b;
      }
      &.confirm_model {
        border: none;
        background: #1e3c81;
        color: #ffffff;
      }
    }
  }
`;
