import React, { useEffect, useState } from 'react';
import { useLocation  } from 'react-router-dom';
import config from 'config';
import CGVWrapper from './CGVWrapper';
import Loader from 'components/Loader';

function CGV({ className }) {
  const [CGV, setCGV] = useState("")
  const [barandName, setBarandName] = useState("")
  const { pathname } = useLocation();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCGV = async () => {
      const path = pathname.split('/')[1];
      const result = await fetch(`${config.managerUrl}/brand/path/${path}`);
      const data = await result.json();
      if (!data?.error && data?.data?.CGV) {
        setCGV(data.data.CGV);
        setBarandName(data.data.brandName);
        setLoading(false);
      }
    }
    fetchCGV()
  })
  return (
    <div className={className}>
      <div className="title">CONDITIONS GÉNÉRALES D’INTERVENTION {barandName}</div>
        { 
          isLoading ? <Loader /> : (
            <div  dangerouslySetInnerHTML={{__html: CGV}} />
          )
        }
    </div>
  )
}

export default CGVWrapper(CGV);
