/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import { map } from 'lodash';
import PropTypes from 'prop-types';
import Wrapper from './TableWrapper';

const columns = [
  { name: 'type', label: 'Type' },
  { name: 'ref', label: 'Référence' },
  { name: 'designation', label: 'Désignation' },
  { name: 'quantity', label: 'Qté' },
  { name: 'unitPrice', label: 'Px Unit' },
  { name: 'ht', label: 'Montant HT' },
  { name: 'tva', label: 'Montant TVA' },
  { name: 'ttc', label: 'Montant TTC' },
];

const TableLine = ({ removeLine, handleChange, values, rowIndex, arrayName }) =>
  values[arrayName][rowIndex] ? (
    <tr>
      {map(columns, (col, index) => (
        <td key={index} className={col.name}>
          <input
            type="text"
            onChange={handleChange}
            value={values[arrayName][rowIndex][col.name]}
            name={`${arrayName}.${rowIndex}.${col.name}`}
          />
        </td>
      ))}
      <td className="action">
        <i className="icon-delete" onClick={() => removeLine()} />
      </td>
    </tr>
  ) : null;

TableLine.propTypes = {
  removeLine: PropTypes.func,
  handleChange: PropTypes.func,
  values: PropTypes.object,
  rowIndex: PropTypes.number,
  arrayName: PropTypes.string,
};

const Table = ({ showTable, setShowTable, formik, arrayHelpers, arrayName }) => {
  const { handleChange, values } = formik;

  const initialState = () =>
    values && values.details && values.details.length
      ? values.details.map((_, index) => ({ TableLine, index }))
      : [{ TableLine, index: 0 }];

  const [lines, setLines] = useState(initialState());

  const addLine = () => {
    arrayHelpers.push({});
    const data = [
      ...lines.map((line, index) => ({ ...line, index })),
      { TableLine, index: lines.length },
    ];
    setLines(data);
  };

  const removeLine = index => {
    arrayHelpers.remove(index);
    const data = lines.filter(el => el.index !== index).map((line, i) => ({ ...line, index: i }));
    setLines(data);
  };

  return (
    <Wrapper showTable={showTable}>
      <table>
        <thead>
          <tr>
            {map(columns, (col, index) => (
              <th key={index} className={col.name}>
                {col.label}
              </th>
            ))}
            <th className="action">
              <i className="icon-close" onClick={() => setShowTable(false)} />
            </th>
          </tr>
        </thead>
        <tbody>
          {lines.map(({ TableLine: Component, index }) => (
            <Component
              removeLine={() => removeLine(index)}
              key={index}
              handleChange={handleChange}
              values={values}
              rowIndex={index}
              arrayName={arrayName}
            />
          ))}
        </tbody>
      </table>
      <div className="table-action">
        <button type="button" className="add-line" onClick={addLine}>
          Ajouter une ligne
        </button>
      </div>
    </Wrapper>
  );
};

Table.propTypes = {
  showTable: PropTypes.bool,
  setShowTable: PropTypes.func,
  formik: PropTypes.object,
  arrayHelpers: PropTypes.object,
  arrayName: PropTypes.string,
};

export default Table;
